import React from 'react';
import './Member.css';

function Member() {
  return (
    <div className="container-fluid member-section">
      <div className="row member-sec-wrap">
        <div className="col-lg-6 col-md-12">
          <img src="/assets/images/employer-chart.png" alt="For Employers" className="img-fluid" />
         <a href="/signup"><button className="green-btn-mob">Join MPLOI today  <img src="/assets/images/arrow-up-left-black.svg" alt=""/> </button></a> 
        </div>
        <div className="col-lg-6 col-md-12  right-content-wrap">
        <button className="pink-btn">For Employers</button>
          <div className='title'>Join MPLOI to Discover Your Perfect <span>Team Member!</span> </div>
          <div className='description'>
            With MPLOI, you're joining a community of visionary entrepreneurs who refuse to settle for the status quo. Embrace the power of AI and experience the difference of a platform designed specifically for you. Your success story starts with MPLOI.
          </div>
         <a href="/signup"> <button className="green-btn">Join MPLOI today  <img src="/assets/images/arrow-up-left-black.svg" alt=""/> </button></a>
        </div>
      </div>
      
      <div className="row ">
        <div className="col-lg-6 col-md-12 ">
          <div className='left-content-wrap'>
          <button className="blue-btn">For Employees</button>
          <div className='title'>Join MPLOI to Find Your Dream Job!</div>
          <div className='description'>
                MPLOI AI isn't just about adapting to the future - it's about creating your own.
            </div>
           <a href="/signup"> <button className="green-btn">Join MPLOI today  <img src="/assets/images/arrow-up-left-black.svg" alt=""/> </button></a>
          </div>
           
        </div>
        <div className="col-lg-6 col-md-12">
          <img src="/assets/images/employ-chart.png" alt="For Employees" className="img-fluid" />
          <a href="/signup"><button className="green-btn-mob">Join MPLOI today  <img src="/assets/images/arrow-up-left-black.svg" alt=""/> </button></a>
        </div>
      </div>
    </div>
  );
}

export default Member;
