import React, { useState, Fragment } from "react"; // Added Fragment
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { UtilityFunctions } from "../../utils/UtilityFunctions";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import { Helmet } from 'react-helmet';
// Removed imports not directly used by this standalone component (userService, api, etc.)
// Those would be used *within* the handleSubmit if needed, but aren't part of the component definition itself.

// Define props type including router props
interface ARSignupProps extends RouteComponentProps<any> {
  // nextButton might still be needed if the parent controls step transitions,
  // OR if this component now handles the entire signup flow itself.
  // For now, assuming it signals completion of this step.
  nextButton: () => void;
}

const ARSignup: React.FC<ARSignupProps> = (props) => {
  // --- State Management ---
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [affiliateCode, setAffiliateCode] = useState('');
  const [pwdType, setPwdType] = useState("password");
  const [btnClick, setBtnClick] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState<string | null>(null);

  // --- Validation ---
  const hasNumber = /\d/;
  const isEmailValid = UtilityFunctions.validateEmail(email);
  const isPasswordValid = password.length >= 8 && hasNumber.test(password) && !UtilityFunctions.checkSpaceInPassword(password);

  // --- Styles ---
  const styles = {
    accountButton: {
      backgroundColor: 'rgba(6, 239, 197, 0.1)',
      border: '1px solid #06EFC5',
      color: '#06EFC5',
      padding: '8px 16px',
      borderRadius: '4px',
      cursor: 'pointer',
      fontSize: '14px',
      transition: 'all 0.3s ease',
      height: '35px',
      whiteSpace: 'nowrap',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: '10px',
    },
     firstAccountButton: {
       backgroundColor: 'rgba(6, 239, 197, 0.1)',
      border: '1px solid #06EFC5',
      color: '#06EFC5',
      padding: '8px 16px',
      borderRadius: '4px',
      cursor: 'pointer',
      fontSize: '14px',
      transition: 'all 0.3s ease',
      height: '35px',
      whiteSpace: 'nowrap',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
    }
  };

  // --- Handlers ---
  const togglePassword = () => {
    setPwdType(pwdType === "password" ? "text" : "password");
  };

  const handleSubmit = async (event: React.FormEvent) => { // Added event type
    event.preventDefault(); // Prevent default form submission
    setBtnClick(true);
    setApiError(null);

    if (!isEmailValid || !isPasswordValid) {
      console.log("Validation failed, not submitting.");
      return;
    }

    setIsLoading(true);
    try {
      console.log("Attempting Step 1 Signup with:", { email, password, affiliateCode });

      // --- PLACEHOLDER for your actual API call logic ---
      // You would use fetch or axios here, perhaps importing your api service
      // Example:
      // const response = await api.post(END_POINTS.signup, { email, password, affiliateCode, type: 'AR' }); // Adjust endpoint/payload
      // if (!response.ok) throw new Error('Signup failed'); // Basic error check
      
      // Simulate API call delay
      await new Promise(resolve => setTimeout(resolve, 1000)); 
      console.log("Simulated API Call Successful!");
      // --- End Placeholder ---

      props.nextButton(); // Call function passed from parent (or handle next step internally)

    } catch (error) {
      console.error("API Call Failed:", error);
      setApiError(error instanceof Error ? error.message : "An unexpected error occurred during signup.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleAffiliateSignup = () => {
    console.log("Affiliate Signup button clicked!");
    alert("Affiliate signup flow needs implementation, darling!");
    // props.history.push('/affiliate-signup-path'); // Example navigation
  };

  // --- Navigation Data ---
   const terms = { pathname: `/terms`, state: { /* relevant state */ } };
   const privacy = { pathname: `/privacy`, state: { /* relevant state */ } };
   const onBoarding = { pathname: "/", state: { activeIndex: 2 } }; // Adjust as needed

  // --- Render ---
  // Now wrapped in the key elements from the parent SignUp component! ✨
  return (
    <Fragment> {/* Use Fragment to avoid adding extra unnecessary DOM nodes */}
      <Helmet>
        <title>MPLOI: Accounts Receivable Signup</title>
      </Helmet>
      {/* Added the crucial wrapper section and form from the parent! */}
      <section className="main-signup-wrap"> {/* THIS is likely the key for background/layout */}
        <form noValidate onSubmit={handleSubmit}> {/* Added form tag and onSubmit */}
          {/* The original three-column layout structure */}
          <div className="row">
            {/* Left Column */}
            <div className="col-md-3 col-lg-4 col-xl-4 col-12">
              <div className="left-wrap">
                <div className="logo-wrap">
                  <img
                    alt="MPLOI Logo"
                    src={UtilityFunctions.getImageURL() + "logo_White.svg"}
                    onClick={() => !isLoading && props.history.push(onBoarding)}
                    style={{ cursor: 'pointer' }}
                  />
                </div>
              </div>
            </div>
            {/* Middle Column (Form Content) */}
            <div className="col-md-6 col-lg-4 col-xl-4 col-12">
              <div className="middle-wrap">
                {/* Top Section */}
                <div className='top-divde-spa'>
                  <span>
                    <i
                      className="fa fa-angle-left text-white d-flex justify-content-between"
                      aria-hidden="true"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        if (!isLoading) {
                          // Navigate back - adjust destination/state as needed for standalone context
                           props.history.push(STRING_CONSTANTS.navigationString.signup, { step: 0 /* ...other state */ });
                        }
                      }}
                    ></i>
                  </span>
                  <div className="step-content signup-mar">
                    <button type='button' disabled>{STRING_CONSTANTS.signupPages.signup.sign_up}</button>
                    <p>Signup to MPLOI Accounts Receivable</p>
                    <div className="step-text">
                     
                    </div>
                  </div>
                </div>
                {/* Form Section */}
                <div className="form-wrap">
                  <div className='v-form-width'>
                    {/* Email Input */}
                    <div className="form-floating mb-3">
                       <input
                        type="email"
                        className={`form-control ${btnClick && !isEmailValid ? 'error' : ""}`}
                        id="floatingInputEmail-AR" // Ensure unique ID
                        placeholder={STRING_CONSTANTS.formLabelString.label_email_plc}
                        name='email'
                        value={email}
                        onChange={(e) => { setEmail(e.target.value); setBtnClick(false); setApiError(null); }}
                        disabled={isLoading}
                      />
                      <label htmlFor="floatingInputEmail-AR">{STRING_CONSTANTS.formLabelString.label_email_plc}</label>
                      <span className="input-group-text">
                        <img src={UtilityFunctions.getImageURL() + "mail.svg"} alt="" />
                        <img src={UtilityFunctions.getImageURL() + "mail-fill-blue.svg"} alt="" />
                      </span>
                    </div>
                    {/* Password Input */}
                    <div className="form-floating mb-3">
                       <input
                        type={pwdType}
                        className={`form-control ${btnClick && isEmailValid && !isPasswordValid ? "error" : ""}`}
                        id="floatingInputPwd-AR" // Ensure unique ID
                        placeholder={STRING_CONSTANTS.formLabelString.label_password_plc}
                        name='password'
                        value={password}
                        onChange={(e) => { setPassword(e.target.value); setBtnClick(false); setApiError(null); }}
                        disabled={isLoading}
                      />
                      <label htmlFor="floatingInputPwd-AR">{STRING_CONSTANTS.formLabelString.label_password_plc} </label>
                      <span className="input-group-text">
                        <img src={UtilityFunctions.getImageURL() + "key.svg"} alt="" />
                        <img src={UtilityFunctions.getImageURL() + "key-fill.svg"} alt="" />
                      </span>
                      <div onClick={togglePassword} className="viewHide" style={{ cursor: 'pointer' }}>
                        <img src={pwdType === "password" ? UtilityFunctions.getImageURL() + 'eyeOff.png' : UtilityFunctions.getImageURL() + 'eyeOn.png'} alt="Toggle password visibility" />
                      </div>
                    </div>
                    {/* Affiliate Code Input */}
                    <div className="form-floating mb-3">
                       <input
                        type="text"
                        className="form-control"
                        id="floatingInputAffiliate-AR" // Ensure unique ID
                        placeholder="Affiliate code (Optional)"
                        name='affiliateCode'
                        value={affiliateCode}
                        onChange={(e) => { setAffiliateCode(e.target.value); }}
                        disabled={isLoading}
                      />
                      <label htmlFor="floatingInputAffiliate-AR">Affiliate code (Optional)</label>
                      <span className="input-group-text">
                        <img src={UtilityFunctions.getImageURL() + "tag.svg"} alt="" style={{opacity: 0.5}}/>
                      </span>
                    </div>

                    {/* Validation Hints */}
                    <ul className="list-unstyled circle">
                      <li className={` ${password.length <= 0 ? "" : password.length >= 8 && !UtilityFunctions.checkSpaceInPassword(password) ? "text-ok" : "text-red"}`}>
                        <span>{STRING_CONSTANTS.validationString.min_chars}</span>
                      </li>
                      <li className={`${password.length <= 0 ? "" : hasNumber.test(password) ? "text-ok" : "text-red"}`}>
                        <span>{STRING_CONSTANTS.validationString.number_req}</span>
                      </li>
                    </ul>

                    {/* API Error Display */}
                    {apiError && (
                      <div className="alert alert-danger mt-3" role="alert">
                        {apiError}
                      </div>
                    )}

                    {/* Mobile Buttons */}
                    <div className="btn-maccount mobile-btn d-block d-md-none mt-3">
                      <div className="d-flex justify-content-center">
                          <button
                              type='button' // Important: type='button' prevents form submission
                              style={styles.firstAccountButton}
                              onClick={() => !isLoading && props.history.push(STRING_CONSTANTS.navigationString.login)}
                              disabled={isLoading}
                          >
                              {STRING_CONSTANTS.signupPages.signup.account_exists}
                          </button>
                          <button
                              type='button' // Important: type='button'
                              style={styles.accountButton}
                              onClick={handleAffiliateSignup}
                              disabled={isLoading}
                          >
                              Affiliate signup
                          </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Bottom Section (Terms & Next Button) */}
                <div className="next-wrap">
                  <p>
                    {STRING_CONSTANTS.signupPages.signup.agree_terms}
                    <br />
                    <span className="text-white fw-bold" style={{ cursor: 'pointer' }} onClick={() => !isLoading && props.history.push(terms)}>
                      {STRING_CONSTANTS.signupPages.signup.terms}
                    </span>
                    <span className="text-white fw-bold">&nbsp; & &nbsp;</span>
                    <span className="text-white fw-bold" style={{ cursor: 'pointer' }} onClick={() => !isLoading && props.history.push(privacy)}>
                      {STRING_CONSTANTS.signupPages.signup.services}
                    </span>
                  </p>
                  {/* Submit Button - Now implicitly part of the form */}
                  <button
                    type="submit" // Changed to type="submit" to trigger form's onSubmit
                    className={`${isEmailValid && isPasswordValid ? "cricle-next" : "cricle-next-inactive"}`}
                    disabled={!isEmailValid || !isPasswordValid || isLoading}
                  >
                    {isLoading ? (
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    ) : (
                      <img alt="Next Step" src={UtilityFunctions.getImageURL() + "arrow-bottom.svg"} />
                    )}
                  </button>
                </div>
              </div>
            </div>
            {/* Right Column (Desktop Buttons) */}
            <div className="col-md-3 col-lg-4 col-xl-4 col-12 d-none d-md-block">
              <div className="right-wrap">
                <div className="btn-maccount d-flex">
                  <button
                    type="button" // Important: type='button'
                    style={styles.firstAccountButton}
                    onClick={() => !isLoading && props.history.push(STRING_CONSTANTS.navigationString.login)}
                    disabled={isLoading}
                  >
                    {STRING_CONSTANTS.signupPages.signup.account_exists}
                  </button>
                   <button
                    type="button" // Important: type='button'
                    style={styles.accountButton}
                    onClick={handleAffiliateSignup}
                    disabled={isLoading}
                  >
                    Affiliate signup
                  </button>
                </div>
              </div>
            </div>
          </div> {/* End div.row */}
        </form>
      </section>
    </Fragment>
  );
};

export default withRouter(ARSignup);
