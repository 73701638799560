import React, { useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { UtilityFunctions } from '../../utils/UtilityFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from "redux";
import { actionCreators, State } from "../../store";
import { cookieService, userService } from '../../_services';
import UpgradePlanCheck from "../Dashboard/Home/upgradePlanCheck";
import { STRING_CONSTANTS } from '../../utils/constants/stringConstants';
import ValidationErrorToast from '../../components/validationErrorToast';
import SuccessToast from '../../components/successToast';
import { fetchSubscriptionChose } from "../../store/reducers/subscriptionPlans/apiThunk";
import SubscriptionModalAccounts from '../Dashboard/Home/SubscriptionModalAccounts';

import moment from 'moment';

const styles = {
    timeline: {
        position: 'relative',
        padding: '20px 0',
    },
    timelineItem: {
        position: 'relative',
        padding: '20px 0 20px 40px',
        borderLeft: '2px solid rgba(255, 255, 255, 0.1)',
    },
    timelineDot: {
        position: 'absolute',
        left: '-6px',
        top: '24px',
        width: '10px',
        height: '10px',
        borderRadius: '50%',
    },
    topBarTitle: {
        color: '#ffffff',
        margin: 0,
        fontSize: '24px',
        fontWeight: '500',
    },
    buttonGroup: {
        display: 'flex',
        gap: '12px',
    },
    button: {
        padding: '10px 20px',
        borderRadius: '6px',
        border: 'none',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        fontSize: '14px',
        fontWeight: '500',
    },
    primaryButton: {
        backgroundColor: '#007bff',
        color: '#ffffff',
    },
    secondaryButton: {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        color: '#ffffff',
    },
    tilesContainer: {
        display: 'flex',
        gap: '24px',
        padding: '48px',
        justifyContent: 'center',
        alignItems: 'stretch',
        maxWidth: '1200px',
        margin: '0 auto',
        flexWrap: 'wrap', // Added to allow wrapping for mobile responsiveness
    },
    tile: {
        flex: '1 1 300px', // Modified to allow better responsiveness
        background: 'rgba(255, 255, 255, 0.05)',
        borderRadius: '12px',
        padding: '48px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        transition: 'all 0.3s ease',
        cursor: 'pointer',
        border: '1px solid rgba(255, 255, 255, 0.1)',
        minWidth: '300px', // Ensures minimum width for readability
    },
    tileIcon: {
        width: '120px',
        height: '120px',
        marginBottom: '24px',
        opacity: '0.8',
    },
    tileTitle: {
        fontSize: '24px',
        fontWeight: '500',
        marginBottom: '16px',
        color: '#ffffff',
    },
    tileText: {
        color: 'rgba(255, 255, 255, 0.6)',
        marginBottom: '24px',
        maxWidth: '400px',
        lineHeight: '1.6',
    },
    connectButton: {
        padding: '12px 24px',
        borderRadius: '6px',
        border: 'none',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: '500',
        backgroundColor: '#007bff',
        color: '#ffffff',
        transition: 'background-color 0.2s',
        width: '200px',
        // Remove the &:hover
    },
    container: {
        backgroundColor: '#1a1a2e',
        minHeight: '100vh',
    },
    topBar: {
        background: 'rgba(255, 255, 255, 0.05)',
        padding: '16px 24px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '24px',
    },
    blueBox: {
        background: 'rgba(255, 255, 255, 0.05)',
        borderRadius: '8px',
        padding: '20px',
        marginBottom: '20px',
    },
    whiteText: {
        color: '#ffffff',
    },
    mutedText: {
        color: 'rgba(255, 255, 255, 0.6)',
    },
    statsCard: {
        display: 'flex',
        alignItems: 'center',
    },
    statsContent: {
        marginLeft: '15px',
    },
    table: {
        width: '100%',
        color: '#fff',
        borderCollapse: 'separate',
        borderSpacing: '0 8px',
    },
    tableHeader: {
        color: 'rgba(255, 255, 255, 0.6)',
        fontWeight: '500',
        padding: '15px',
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
        textAlign: 'left',
    },
    tableCell: {
        padding: '15px',
        borderTop: '1px solid rgba(255, 255, 255, 0.1)',
        color: '#fff',
    },
    badge: {
        padding: '6px 12px',
        borderRadius: '4px',
        fontWeight: '500',
        display: 'inline-block',
    },
    emptyState: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '48px',
        textAlign: 'center',
        color: '#ffffff',
    },
    quickbooksButton: {
        backgroundColor: '#2CA01C', // QuickBooks green color
        color: '#ffffff',
    },
    collectionsDisabledBanner: {
        background: 'rgba(220, 53, 69, 0.1)',
        border: '1px solid #dc3545',
        borderRadius: '8px',
        padding: '16px 24px',
        marginBottom: '24px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    bannerText: {
        color: '#dc3545',
        margin: 0,
        fontSize: '16px',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
    },
    enableButton: {
        backgroundColor: '#dc3545',
        color: '#ffffff',
        border: 'none',
        borderRadius: '4px',
        padding: '8px 16px',
        cursor: 'pointer',
        fontSize: '14px',
        fontWeight: '500',
    },
};

const capitalizeFirst = (str) => {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
};

const getOutcomeColor = (outcome) => {
    switch (outcome?.toLowerCase() || '') {
        case 'positive':
            return '#28a745';
        case 'neutral':
            return '#ffc107';
        case 'negative':
            return '#dc3545';
        default:
            return '#6c757d';
    }
};

const AccountsReceivable = (props) => {
    const dispatch = useDispatch();
    // Add this line to get the state from Redux
    const state = useSelector((state: State) => state);
    const [userData, setUserData] = useState(null);
    const { setUnreadNotificationCount } = bindActionCreators(actionCreators, dispatch);
    const [xeroConnected, setXeroConnected] = useState(false);
    const [isPaid, setIsPaid] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showGuestAccessPrompt, setShowGuestAccessPrompt] = useState(false);
    const [unreadMsgCount, setUnreadMsgCount] = useState(0);
    const user = cookieService?.getCookie(STRING_CONSTANTS?.cookieStrings?.user);
    const isUserLoggedIn = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.token);
    const [globalOrganizationId, setGlobalOrganizationId] = useState(null);
    const [initialLoading, setInitialLoading] = useState(true);
    const [subsModalVisibility, setSubsModalVisibility] = useState(false);
    const [collectionsEnabled, setCollectionsEnabled] = useState(false);

    const [stats, setStats] = useState({
        callsToday: 0,
        totalOutstanding: 0,
        successfulCollections: 0,
        totalCollected: 0
    });
    const [showTestCallModal, setShowTestCallModal] = useState(false);
    const [testCallPhone, setTestCallPhone] = useState('');
    const [selectedAccount, setSelectedAccount] = useState(null);    
    const [subsModalAccounts, setSubsModalAccounts] = useState(false);
    const [myobConnected, setMyobConnected] = useState(false);
    const [myobLoading, setMyobLoading] = useState(false);
    const [showMyobAuthModal, setShowMyobAuthModal] = useState(false);
    const [selectedCompanyFiles, setSelectedCompanyFiles] = useState([]);
    const [companyFileCredentials, setCompanyFileCredentials] = useState({});
    interface QuickBooksCompanyInfo {
        id: string;
        name: string;
        email?: string;
        phone?: string;
    }

    const [quickbooksLoading, setQuickbooksLoading] = useState(false);
    const [quickbooksConnected, setQuickbooksConnected] = useState(false);
    const [quickbooksCompanyInfo, setQuickbooksCompanyInfo] = useState<QuickBooksCompanyInfo | null>(null);

    useEffect(() => {
        if (isUserLoggedIn) {
            userService.getUserData()
                .then(res => {
                    cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, res)
                    setUnreadNotificationCount(res?.user?.unreadNotificationCount)
                    setUserData(res)
                    setTestCallPhone(res?.user?.mobileNumber)
                })
        }
    }, []);

    useEffect(() => {
        const fetchDataChose = async () => {
          try {
            const res = await dispatch(fetchSubscriptionChose());
              if (Object.keys(res.payload).length === 0) {
                setSubsModalAccounts(true);
                setIsPaid(false);
              } else {
                setSubsModalAccounts(false);
                setIsPaid(true);
              }
          } catch (error) {
            console.error("An error occurred:", error);
          }
        };
        fetchDataChose();
    }, []);

    const [globalInteractions, setGlobalInteractions] = useState([]);

    const fetchGlobalInteractions = async () => {
        try {
            const response = await userService.aiRequest({
                method: 'get-global-interactions',
                data: { limit: 50, organizationId: globalOrganizationId }  // Limit to 50 most recent interactions
            });

            if (response.data?.interactions) {
                setGlobalInteractions(response.data.interactions.map(interaction => ({
                    id: interaction.id,
                    type: interaction.type,
                    direction: interaction.direction,
                    timestamp: interaction.timestamp,
                    duration: interaction.duration,
                    outcome: interaction.outcome,
                    summary: interaction.summary,
                    recordingUrl: interaction.recordingUrl,
                    transcript: interaction.transcript,
                    amount: interaction.amount,
                    method: interaction.method,
                    reference: interaction.reference,
                    customer: {
                        id: interaction.xeroContactId,
                        organizationId: interaction.xeroOrganizationId,
                        name: interaction.customerName,
                        email: interaction.customerEmail,
                        phone: interaction.customerPhone
      
                    }
                })));
            }
        } catch (error) {
            console.error('Error fetching global interactions:', error);
        }
    };

    useEffect(() => {
        if (xeroConnected || quickbooksConnected) {
            fetchGlobalInteractions();
        }
    }, [xeroConnected, quickbooksConnected]);

    const initiateCall = async (organizationId, contactId, invoiceId = null) => {
        try {
            const response = await userService.aiRequest({
                method: 'initiate-call',
                data: {
                    organizationId,
                    contactId,
                    invoiceId,
                }
            }, true);

            if (response.data?.success) {
                SuccessToast("Success", "Call successfully initiated.");
            } else {
                ValidationErrorToast("Error", `Call could not be initiated: ${response.data?.error || 'Unknown error'}`);
            }
        } catch (error) {
            ValidationErrorToast("Error", `Call could not be initiated: ${error.message || 'Unknown error'}`);
        }
    };

    const handleXeroConnection = async () => {
        try {
            setLoading(true);
            const account_id = user.id;
            if (window.location.host.includes('localhost')) {
                window.location.href = `http://localhost:5000/connect/${account_id}`;
            } else {
                window.location.href = `https://ai.mploi.com/connect/${account_id}`;
            }
        } catch (error) {
            ValidationErrorToast("Error", "Failed to connect to Xero");
            setLoading(false);
        }
    };

    const handleQuickbooksConnection = async () => {
        try {
            setQuickbooksLoading(true);
            const account_id = user.id;
            if (window.location.host.includes('localhost')) {
                window.location.href = `http://localhost:5000/connect-quickbooks/${account_id}`;
            } else {
                window.location.href = `https://ai.mploi.com/connect-quickbooks/${account_id}`;
            }
        } catch (error) {
            ValidationErrorToast("Error", "Failed to connect to QuickBooks");
            setQuickbooksLoading(false);
        }
    };

    const handleMyobConnection = async () => {
        try {
            setMyobLoading(true);
            const account_id = user.id;
            if (window.location.host.includes('localhost')) {
                window.location.href = `http://localhost:5000/myob-connect/${account_id}`;
            } else {
                window.location.href = `https://ai.mploi.com/myob-connect/${account_id}`;
            }
        } catch (error) {
            ValidationErrorToast("Error", "Failed to connect to MYOB");
            setMyobLoading(false);
        }
    };

    const handleTestCall = async () => {
        if (!testCallPhone) {
            ValidationErrorToast("Error", "Please enter a phone number");
            return;
        }
    
        try {
            const response = await userService.aiRequest({
                method: 'initiate-call',
                data: {
                    organizationId: selectedAccount.organizationId,
                    contactId: selectedAccount.contactId,
                    invoiceId: selectedAccount.id,
                    testPhone: testCallPhone,
                    isTest: true
                }
            }, true);
    
            if (response.data?.success) {
                SuccessToast("Success", "Test call initiated successfully");
                setShowTestCallModal(false);
                setTestCallPhone('');
                setSelectedAccount(null);
            } else {
                ValidationErrorToast("Error", `Call could not be initiated: ${response.data?.error || 'Unknown error'}`);
            }
        } catch (error) {
            ValidationErrorToast("Error", `Call could not be initiated: ${error.message || 'Unknown error'}`);
        }
    };    

    // State declarations
    const [invoiceData, setInvoiceData] = useState({
        invoices: [],
        summary: {
            total_invoices: 0,
            overdue_summary: {
                overdue_7_days: 0,
                overdue_14_days: 0,
                overdue_30_days: 0,
                overdue_60_days: 0,
                overdue_90_days: 0
            },
            call_summary: {
                total_calls: 0,
                invoices_with_payment_plans: 0,
                invoices_needing_followup: 0
            },
        }
    });

    // useEffect to check connection and load data
    useEffect(() => {
        const checkConnections = async () => {
            try {
                const account_id = user.id;
    
                // Check all connections in parallel
                const [myobResponse, xeroResponse, quickbooksResponse] = await Promise.all([
                    userService.aiRequest({
                        data: { account_id },
                        method: 'myob-check-connection'
                    }),
                    userService.aiRequest({
                        data: { account_id }, 
                        method: 'xero-check-connection'
                    }),
                    userService.aiRequest({
                        data: { account_id },
                        method: 'quickbooks-check-connection'
                    })
                ]);
    
                // Handle MYOB connection
                const myobConnectionId = myobResponse.data?.organizationId;
                if (myobResponse.data?.connected) {
                    setMyobConnected(true);
                    setSelectedCompanyFiles(myobResponse.data.companyFiles);
                    
                    const needsAuth = myobResponse.data.companyFiles.some(
                        file => file.requiresAuth && !file.connected
                    );
                    if (needsAuth) {
                        setShowMyobAuthModal(true);
                    }
                }
    
                // Handle Xero connection
                const xeroOrganizationId = xeroResponse.data?.organizationId;
                if (xeroOrganizationId) {
                    setXeroConnected(true);
                    setGlobalOrganizationId(xeroOrganizationId);
                    setCollectionsEnabled(xeroResponse.data.collectionsEnabled || false);
    
                    if (!xeroResponse.data.paidAt) {
                        setSubsModalVisibility(true);
                    } else {
                        setSubsModalVisibility(false);
                    }
                }
    
                // Handle QuickBooks connection
                const quickbooksOrganizationId = quickbooksResponse.data?.organizationId;
                if (quickbooksResponse.data?.connected) {
                    setQuickbooksConnected(true);
                    if (!xeroOrganizationId) {  // Use QuickBooks org if Xero isn't connected
                        setGlobalOrganizationId(quickbooksOrganizationId);
                        setCollectionsEnabled(quickbooksResponse.data.collectionsEnabled || false);
                    }
                }
    
                // If any connection is active, fetch invoice data
                const activeOrganizationId = xeroOrganizationId || quickbooksOrganizationId || myobConnectionId;
                if (activeOrganizationId) {
                    setLoading(true);
    
                    const invoiceResponse = await userService.aiRequest({
                        data: { organization_id: activeOrganizationId }, 
                        method: 'get-account-invoices'
                    });
    
                    if (invoiceResponse.data) {
                        setInvoiceData(invoiceResponse.data);
                        
                        setStats({
                            callsToday: invoiceResponse.data.summary.stats.callsToday,
                            totalOutstanding: invoiceResponse.data.summary.stats.totalOutstanding,
                            successfulCollections: invoiceResponse.data.summary.stats.successRate,
                            totalCollected: invoiceResponse.data.summary.stats.totalCollected
                        });
    
                        // Map invoices with source tracking
                        const accounts = invoiceResponse.data.invoices.map(invoice => ({
                            id: invoice.invoiceID || invoice.quickbooksInvoiceId,
                            organizationId: invoice.organizationId,
                            contactId: invoice.xeroContactId || invoice.quickbooksCustomerId,
                            customerName: invoice.contact?.name || 'Unknown Customer',
                            invoiceNumber: invoice.invoiceNumber || invoice.docNumber,
                            amount: parseFloat(invoice.amountDue || 0),
                            dueDate: invoice.overdueFlags.dueDate,
                            status: invoice.Status || invoice.status,
                            email: invoice.contact?.emailAddress || 'No email provided',
                            phone: invoice.contact?.phones?.[0]?.phoneNumber || 'No phone provided',
                            lastContact: invoice.lastContact || null,
                            overdue_flags: invoice.overdueFlags || {
                                daysOverdue: 0,
                                needsToFollowup: true
                            },
                            call_history: invoice.call_history || {
                                calls: [],
                                totalCalls: 0,
                                hasPaymentPlan: false,
                                latestOutcome: null
                            },
                            source: invoice.quickbooksInvoiceId ? 'quickbooks' : 
                                   invoice.invoiceID ? 'xero' : 
                                   invoice.myobInvoiceId ? 'myob' : 'unknown'
                        }));
        
                        setAccounts(accounts);
                    }
                } else {
                    setXeroConnected(false);
                    setQuickbooksConnected(false);
                }
            } catch (error) {
                // console.error('Error loading account data:', error);
                // setXeroConnected(false);
                // setQuickbooksConnected(false);
            } finally {
                setInitialLoading(false);
                setLoading(false);
            }
        };
    
        if (userData) {
            checkConnections();
        }
    }, [userData]);

    const MyobAuthModal = () => {
        const handleSubmit = async () => {
            try {
                // Validate that credentials are provided for all selected files
                const missingCredentials = selectedCompanyFiles.some(file => 
                    file.requiresAuth && (!companyFileCredentials[file.id]?.username || 
                    !companyFileCredentials[file.id]?.password)
                );
    
                if (missingCredentials) {
                    ValidationErrorToast("Error", "Please provide credentials for all selected company files");
                    return;
                }
    
                // Submit credentials for each file
                for (const file of selectedCompanyFiles) {
                    if (file.requiresAuth) {
                        const response = await userService.aiRequest({
                            method: 'myob-authenticate-file',
                            data: {
                                accountId: user.id,
                                fileId: file.id,
                                username: companyFileCredentials[file.id].username,
                                password: companyFileCredentials[file.id].password
                            }
                        });
    
                        if (!response.data?.success) {
                            throw new Error(`Failed to authenticate ${file.name}`);
                        }
                    }
                }
    
                SuccessToast("Success", "MYOB company files authenticated successfully");
                setShowMyobAuthModal(false);
                
                // Refresh connection status
                checkConnections();
    
            } catch (error) {
                ValidationErrorToast("Error", `Authentication failed: ${error.message}`);
            }
        };
    
        return (
            <div className="modal" style={{
                display: showMyobAuthModal ? 'block' : 'none',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 1000
            }}>
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: '#1a1a2e',
                    padding: '24px',
                    borderRadius: '12px',
                    width: '90%',
                    maxWidth: '500px',
                    border: '1px solid rgba(255, 255, 255, 0.1)'
                }}>
                    <h3 style={{...styles.whiteText, marginBottom: '20px'}}>
                        MYOB Company File Authentication
                    </h3>
                    
                    {selectedCompanyFiles.map(file => (
                        <div key={file.id} style={{marginBottom: '20px'}}>
                            <h4 style={styles.whiteText}>{file.name}</h4>
                            {file.requiresAuth && (
                                <div style={{marginTop: '10px'}}>
                                    <input
                                        type="text"
                                        placeholder="Username"
                                        value={companyFileCredentials[file.id]?.username || ''}
                                        onChange={(e) => setCompanyFileCredentials(prev => ({
                                            ...prev,
                                            [file.id]: {
                                                ...prev[file.id],
                                                username: e.target.value
                                            }
                                        }))}
                                        style={{
                                            width: '100%',
                                            padding: '10px',
                                            marginBottom: '10px',
                                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                            border: '1px solid rgba(255, 255, 255, 0.2)',
                                            borderRadius: '6px',
                                            color: '#fff'
                                        }}
                                    />
                                    <input
                                        type="password"
                                        placeholder="Password"
                                        value={companyFileCredentials[file.id]?.password || ''}
                                        onChange={(e) => setCompanyFileCredentials(prev => ({
                                            ...prev,
                                            [file.id]: {
                                                ...prev[file.id],
                                                password: e.target.value
                                            }
                                        }))}
                                        style={{
                                            width: '100%',
                                            padding: '10px',
                                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                            border: '1px solid rgba(255, 255, 255, 0.2)',
                                            borderRadius: '6px',
                                            color: '#fff'
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    ))}
    
                    <div style={{
                        display: 'flex',
                        gap: '12px',
                        marginTop: '24px',
                        justifyContent: 'flex-end'
                    }}>
                        <button
                            style={{...styles.button, ...styles.secondaryButton}}
                            onClick={() => setShowMyobAuthModal(false)}
                        >
                            Cancel
                        </button>
                        <button
                            style={{...styles.button, ...styles.primaryButton}}
                            onClick={handleSubmit}
                        >
                            Authenticate
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    const CollectionsDisabledBanner = ({ onEnableClick }) => (
        <div style={styles.collectionsDisabledBanner}>
            <p style={styles.bannerText}>
                <span role="img" aria-label="warning">⚠️</span>
                Collections are currently disabled. Enable collections to start managing overdue invoices.
            </p>
            <button 
                style={styles.enableButton}
                onClick={onEnableClick}
            >
                Enable Collections
            </button>
        </div>
    );

    const EmptyState = () => (
        <div style={styles.tilesContainer}>
            {/* Xero Tile */}
            <div style={styles.tile}>
                <img 
                    src={UtilityFunctions.getImageURL() + "xero.png"} 
                    alt="Connect Xero"
                    style={styles.tileIcon}
                />
                <h2 style={styles.tileTitle}>Connect Your Xero Account</h2>
                <p style={styles.tileText}>
                    Connect your Xero account to start managing your accounts receivable. 
                    Track invoices, manage collections, and automate follow-ups.
                </p>
                <button 
                    style={styles.connectButton}
                    disabled={loading}
                    onClick={handleXeroConnection}
                >
                    {loading ? "Connecting..." : "Connect Xero"}
                </button>
            </div>

            {/* QuickBooks Tile */}
            <div style={styles.tile}>
                <img 
                    src={UtilityFunctions.getImageURL() + "quickbooks.webp"} 
                    alt="Connect QuickBooks"
                    style={styles.tileIcon}
                />
                <h2 style={styles.tileTitle}>Connect Your QuickBooks Account</h2>
                <p style={styles.tileText}>
                    Link your QuickBooks account to streamline your accounts receivable process.
                    Manage invoices, track payments, and automate collections.
                </p>
                <button 
                    style={{
                        ...styles.connectButton, 
                        backgroundColor: quickbooksConnected ? '#28a745' : '#2CA01C'
                    }}
                    disabled={quickbooksLoading}
                    onClick={handleQuickbooksConnection}
                >
                    {quickbooksLoading ? "Connecting..." : 
                    quickbooksConnected ? "Connected ✓" : "Connect QuickBooks"}
                </button>
                {quickbooksConnected && quickbooksCompanyInfo && (
                    <p style={{...styles.mutedText, marginTop: '10px'}}>
                        Connected to: {quickbooksCompanyInfo.name}
                    </p>
                )}
            </div>

            {/* MYOB Tile */}
            <div style={styles.tile}>
                <img 
                    src={UtilityFunctions.getImageURL() + "myob.png"} 
                    alt="Connect MYOB"
                    style={styles.tileIcon}
                />
                <h2 style={styles.tileTitle}>Connect Your MYOB Account</h2>
                <p style={styles.tileText}>
                    Link your MYOB account to streamline your accounts receivable process.
                    Manage invoices, track payments, and automate collections.
                </p>
                <button 
                    style={{...styles.connectButton}}
                    onClick={handleMyobConnection}
                    disabled={myobLoading}
                >
                    {myobLoading ? "Connecting..." : "Connect MYOB"}
                </button>
            </div>

        </div>
    );

    const Dashboard = () => (
        <div className="row">
            {!collectionsEnabled && (
                <div className="col-xl-12">
                    <CollectionsDisabledBanner 
                        onEnableClick={() => props.history.push(`/ar/settings/${globalOrganizationId}`)}
                    />
                </div>
            )}
            {loading ? (
                <div style={{...styles.emptyState, color: '#fff'}}>
                    Loading data...
                </div>
            ) : (
                <>
                    <div className="col-xl-12">
                        <div className="row mb-4">
                            <div className="col-xl-4 col-md-6">
                                <div style={styles.blueBox}>
                                    <div style={styles.statsCard}>
                                        <div style={styles.statsContent}>
                                            <h4 style={styles.mutedText}>Calls Today</h4>
                                            <h2 style={styles.whiteText}>{stats.callsToday}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6">
                                <div style={styles.blueBox}>
                                    <div style={styles.statsCard}>
                                        <div style={styles.statsContent}>
                                            <h4 style={styles.mutedText}>Outstanding</h4>
                                            <h2 style={styles.whiteText}>
                                                ${stats.totalOutstanding.toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                })}
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6">
                                <div style={styles.blueBox}>
                                    <div style={styles.statsCard}>
                                        <div style={styles.statsContent}>
                                            <h4 style={styles.mutedText}>Total Collected</h4>
                                            <h2 style={styles.whiteText}>
                                                ${stats.totalCollected.toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                })}
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div style={styles.blueBox}>
                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px'}}>
                                <h3 style={styles.whiteText}>Outstanding Invoices</h3>
                                <div style={styles.mutedText}>
                                    Invoices 14+ days overdue
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table style={styles.table}>
                                    <thead>
                                        <tr>
                                            <th style={styles.tableHeader}>Customer</th>
                                            <th style={styles.tableHeader}>Invoice #</th>
                                            <th style={styles.tableHeader}>Amount</th>
                                            <th style={styles.tableHeader}>Due Date</th>
                                            <th style={styles.tableHeader}>Status</th>
                                            <th style={styles.tableHeader}>Last Contact</th>
                                            <th style={styles.tableHeader}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {accounts.map(account => (
                                            <tr key={account.id}>
                                                <td style={styles.tableCell}>
                                                    <div>
                                                        <div style={styles.whiteText}>{account.customerName}</div>
                                                    </div>
                                                </td>
                                                <td style={styles.tableCell}>{account.invoiceNumber}</td>
                                                <td style={styles.tableCell}>${account.amount.toLocaleString()}</td>
                                                <td style={styles.tableCell}>
                                                    {moment(account.dueDate).format('DD/MM/YYYY')}
                                                </td>
                                                <td style={styles.tableCell}>
                                                    <span style={{
                                                        ...styles.badge,
                                                        backgroundColor: account.overdue_flags?.daysOverdue > 90 ? '#dc3545' :
                                                                       account.overdue_flags?.daysOverdue > 30 ? '#ffc107' :
                                                                       account.overdue_flags?.daysOverdue > 0 ? '#17a2b8' : 
                                                                       '#28a745'
                                                    }}>
                                                        {account.overdue_flags?.daysOverdue > 0 ? 
                                                            `${account.overdue_flags?.daysOverdue} days` : 
                                                            'Current'}
                                                    </span>
                                                </td>
                                                <td style={styles.tableCell}>
                                                    {account.lastContact ? 
                                                        moment(account.lastContact).local().format('DD/MM/YYYY HH:mm') : 
                                                        'Never'}
                                                </td>
                                                <td style={styles.tableCell}>
                                                    <div style={{ display: 'flex', gap: '8px' }}>
                                                        <button 
                                                            style={{...styles.button, ...styles.primaryButton}}
                                                            onClick={() => initiateCall(account.organizationId, account.contactId)}
                                                        >
                                                            Call
                                                        </button>
                                                        <button 
                                                            style={{...styles.button, ...styles.secondaryButton}}
                                                            onClick={() => {
                                                                setSelectedAccount(account);
                                                                setShowTestCallModal(true);
                                                            }}
                                                        >
                                                            Test Call
                                                        </button>
                                                        <button 
                                                            style={{...styles.button, ...styles.secondaryButton}}
                                                            onClick={() => props.history.push(`/ar/view/${account.organizationId}/${account.contactId}`)}
                                                        >
                                                            View
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {/* Global Interaction History */}
                        <div style={styles.blueBox}>
                            <h3 style={{...styles.whiteText, marginBottom: '20px'}}>
                                <span style={{marginRight: '10px'}}>📋</span>
                                Recent Activity
                            </h3>
                            <div style={styles.timeline}>
                                {globalInteractions.map((interaction) => (
                                    <div key={interaction.id} style={styles.timelineItem}>
                                        <div style={{
                                            ...styles.timelineDot,
                                            backgroundColor: interaction.type === 'call' 
                                                ? getOutcomeColor(interaction.outcome)
                                                : '#28a745'
                                        }} />
                                        
                                        <div style={{marginBottom: '15px'}}>
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'flex-start',
                                                marginBottom: '10px'
                                            }}>
                                                <div>
                                                    <h4 style={styles.whiteText}>
                                                        {interaction.type === 'call' ? (
                                                            <>
                                                                {interaction.direction === 'outbound' ? '📤' : '📥'} &nbsp;
                                                                {interaction.direction === 'outbound' ? 'Outbound Call' : 'Inbound Call'}
                                                            </>
                                                        ) : (
                                                            <>
                                                                💵 &nbsp;Payment Received
                                                            </>
                                                        )}
                                                        {' - '}
                                                        <Link 
                                                            to={`/ar/view/${interaction.customer.organizationId}/${interaction.customer.id}`}
                                                            style={{color: '#007bff', textDecoration: 'none'}}
                                                        >
                                                            {interaction.customer.name}
                                                        </Link>
                                                    </h4>
                                                    <p style={styles.mutedText}>
                                                        {moment(interaction.timestamp).local().format('DD/MM/YYYY HH:mm')}
                                                    </p>
                                                </div>
                                                {interaction.type === 'call' && (
                                                    <span style={{
                                                        ...styles.badge,
                                                        backgroundColor: getOutcomeColor(interaction.outcome)
                                                    }}>
                                                        {capitalizeFirst(interaction.outcome) || 'Neutral'}
                                                    </span>
                                                )}
                                            </div>

                                            {interaction.type === 'call' ? (
                                                <>
                                                    <p style={styles.whiteText}>
                                                        Duration: {formatDuration(interaction.duration)}
                                                    </p>
                                                    <p style={styles.mutedText}>
                                                        {interaction.summary}
                                                    </p>
                                                </>
                                            ) : (
                                                <>
                                                    <p style={styles.whiteText}>
                                                        Amount: ${interaction.amount.toLocaleString()}
                                                    </p>
                                                    <p style={styles.mutedText}>
                                                        Method: {interaction.method}
                                                    </p>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>
                </>
            )}
        </div>
    );

    const handlePhoneChange = useCallback((e) => {
        setTestCallPhone(e.target.value);
    }, []);

    // Replace your current TestCallModal component with this one
    const TestCallModal = () => {
        // Create a ref for the input element
        const inputRef = React.useRef(null);
        
        // Focus the input when the modal opens
        React.useEffect(() => {
            if (showTestCallModal && inputRef.current) {
                inputRef.current.focus();
            }
        }, [showTestCallModal]);

        return (
            <div className="modal" style={{
                display: showTestCallModal ? 'block' : 'none',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 1000
            }}>
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: '#1a1a2e',
                    padding: '24px',
                    borderRadius: '12px',
                    width: '90%',
                    maxWidth: '500px',
                    border: '1px solid rgba(255, 255, 255, 0.1)'
                }}>
                    <h3 style={{...styles.whiteText, marginBottom: '20px'}}>Test Call</h3>
                    <p style={styles.mutedText}>
                        This will initiate a test call for {selectedAccount?.customerName || ''}, 
                        but route it to your specified phone number instead of the customer.
                    </p>
                    <input
                        ref={inputRef}
                        type="tel"
                        value={testCallPhone}
                        onChange={handlePhoneChange}
                        placeholder="Enter your phone number"
                        style={{
                            width: '100%',
                            padding: '10px',
                            marginTop: '20px',
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                            border: '1px solid rgba(255, 255, 255, 0.2)',
                            borderRadius: '6px',
                            color: '#fff'
                        }}
                    />
                    <div style={{
                        display: 'flex',
                        gap: '12px',
                        marginTop: '24px',
                        justifyContent: 'flex-end'
                    }}>
                        <button
                            style={{...styles.button, ...styles.secondaryButton}}
                            onClick={() => {
                                setShowTestCallModal(false);
                                setTestCallPhone('');
                                setSelectedAccount(null);
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            style={{...styles.button, ...styles.primaryButton}}
                            onClick={() => handleTestCall()}
                        >
                            Start Test Call
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <section className="main-dashboard-wrap" style={styles.container}>
            <Helmet>
                <title>MPLOI: Accounts Receivable</title>
            </Helmet>
            <div id="wrapper" style={{ height: "100vh" }}>
                {/* Sidebar */}
                <ul className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ${state.common.showSidebarOnMobileView ? "active" : ""}`} id="accordionSidebar">
                    <div>
                        <Link style={{ pointerEvents: "none" }} className="sidebar-brand d-flex align-items-center justify-content-center" to="#">
                            <img src={UtilityFunctions.getImageURL() + "db-logo.svg"} />
                        </Link>
                        {!user?.user?.isAccountsSignup && <li className="nav-item">
                            <Link className="nav-link" to="/dashboard/home">
                                <img src={UtilityFunctions.getImageURL() + "home-icon-blank-white.svg"} alt="" />
                                <img src={UtilityFunctions.getImageURL() + "home-icon-fill-green.svg"} alt="" />
                            </Link>
                        </li>}
                        {user?.user?.isAccountsSignup && <li className="nav-item">
                            <Link className="nav-link" to="/ar">
                                <img src={UtilityFunctions.getImageURL() + "home-icon-blank-white.svg"} alt="" />
                                <img src={UtilityFunctions.getImageURL() + "home-icon-fill-green.svg"} alt="" />
                            </Link>
                        </li>}

                        {!user?.user?.isAccountsSignup && <li className="nav-item notification-nav">
                            <Link className="nav-link" to="/dashboard/chat/">
                                <div className="svg-box">
                                    <img src={UtilityFunctions.getImageURL() + "chat-icon.svg"} alt="" />
                                    <img src={UtilityFunctions.getImageURL() + "chat-icon-fill-green.svg"} alt="" />
                                    {unreadMsgCount ? <span >{unreadMsgCount > 9 ? "9+" : unreadMsgCount}</span> : ""}
                                </div>
                            </Link>
                        </li>}
    
                        {user?.user?.type === STRING_CONSTANTS.userType.employer && <li className="nav-item">
                            <Link className="nav-link" to="/dashboard/modules">
                                <img src={UtilityFunctions.getImageURL() + "employeesLandingPage/exploreFifth.svg"} 
                                    style={{ filter: 'brightness(0) invert(1)' }} alt="" />
                                <img src={UtilityFunctions.getImageURL() + "employeesLandingPage/exploreFifth.svg"} 
                                    style={{ filter: 'brightness(0) invert(1)' }} alt="" />
                            </Link>
                        </li>}
                    </div>
                    <div>
                        <li className="nav-item">
                            <UpgradePlanCheck
                                isUserLoggedIn={isUserLoggedIn}
                                setShowGuestAccessPrompt={setShowGuestAccessPrompt}
                                userData={userData}
                            />
                        </li>
                    </div>
                    <div>
                        <li className="nav-item notification-nav">
                            <span className="nav-link"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    isUserLoggedIn ?
                                        props.history.push(STRING_CONSTANTS.navigationString.dashboard.notifications) :
                                        setShowGuestAccessPrompt(true)
                                }}
                            >
                                <div className="svg-box">
                                    <img src={UtilityFunctions.getImageURL() + "bell-icon-blank-white.svg"} alt="" />
                                    <img src={UtilityFunctions.getImageURL() + "bell-icon-fill-green.svg"} alt="" />
                                    {state?.unreadNotificationCount?.unreadNotificationCount ? 
                                        <span>
                                            {state?.unreadNotificationCount?.unreadNotificationCount > 9 ? 
                                                "9+" : state?.unreadNotificationCount?.unreadNotificationCount}
                                        </span> : ""}
                                </div>
                            </span>
                        </li>
                        <li className="nav-item">
                            <span className="nav-link"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    isUserLoggedIn ?
                                        props.history.push(STRING_CONSTANTS.navigationString.dashboard.settings) :
                                        setShowGuestAccessPrompt(true)
                                }}
                            >
                                <img src={UtilityFunctions.getImageURL() + "settings-icon-blank-white.svg"} alt="" />
                                <img src={UtilityFunctions.getImageURL() + "settings-icon-fill-green.svg"} alt="" />
                            </span>
                        </li>
                        <li className='profile-pic d-grid justify-content-center gap-2 text-white'>
                            <img src={
                                userData?.user?.type === STRING_CONSTANTS.userType.employer ? 
                                    (userData?.logo || UtilityFunctions.getImageURL() + "profile-pic.png") : 
                                    (userData?.profilePhoto || UtilityFunctions.getImageURL() + "default-employee-profile-pic-round.svg")
                            } />
                            {!isUserLoggedIn && 
                                <span style={{ cursor: "pointer" }}
                                    onClick={() => props.history.push({
                                        pathname: STRING_CONSTANTS.navigationString.signup,
                                        state: { isGuest: true }
                                    })}
                                >
                                    Signup
                                </span>
                            }
                        </li>
                    </div>
                </ul>
    
                {/* Content Wrapper */}
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        {/* Top Bar */}
                        <div style={styles.topBar}>
                            <h1 style={styles.topBarTitle}>Accounts Receivable</h1>
                            <div style={styles.buttonGroup}>
                                <button 
                                    style={{...styles.button, ...styles.secondaryButton}}
                                    onClick={() => props.history.push(`/ar/settings/${globalOrganizationId}`)}
                                >
                                    Settings
                                </button>
                            </div>
                        </div>
    
                        {/* Main Content */}
                        <div className="container-fluid">
                            {initialLoading ? (
                                <div /> // Empty div while checking connection
                            ) : !xeroConnected && !quickbooksConnected && !myobConnected ? (
                                <EmptyState />
                            ) : (
                                <Dashboard />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <TestCallModal />
            <MyobAuthModal />
            <SubscriptionModalAccounts
                subsModal={subsModalVisibility}
                setSubsModal={setSubsModalVisibility}
                history={props.history}
            />
        </section>
    );
};

export default AccountsReceivable;