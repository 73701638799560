import React, { useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { UtilityFunctions } from '../../utils/UtilityFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from "redux";
import { actionCreators, State } from "../../store";
import { cookieService, userService } from '../../_services';
import UpgradePlanCheck from "../Dashboard/Home/upgradePlanCheck";
import { STRING_CONSTANTS } from '../../utils/constants/stringConstants';
import ValidationErrorToast from '../../components/validationErrorToast';
import SuccessToast from '../../components/successToast';
import { fetchSubscriptionChose } from "../../store/reducers/subscriptionPlans/apiThunk";
import SubscriptionModalAccounts from '../Dashboard/Home/SubscriptionModalAccounts';
import moment from 'moment';

// Styles object
const styles = {
    container: {
        backgroundColor: '#1a1a2e',
        minHeight: '100vh',
    },
    topBar: {
        background: 'rgba(255, 255, 255, 0.05)',
        padding: '16px 24px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '24px',
    },
    topBarTitle: {
        color: '#ffffff',
        margin: 0,
        fontSize: '24px',
        fontWeight: '500',
    },
    buttonGroup: {
        display: 'flex',
        gap: '12px',
    },
    button: {
        padding: '10px 20px',
        borderRadius: '6px',
        border: 'none',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        fontSize: '14px',
        fontWeight: '500',
    },
    primaryButton: {
        backgroundColor: '#007bff',
        color: '#ffffff',
    },
    secondaryButton: {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        color: '#ffffff',
    },
    section: {
        background: 'rgba(255, 255, 255, 0.05)',
        backdropFilter: 'blur(10px)',
        borderRadius: '12px',
        padding: '24px',
        marginBottom: '24px',
        border: '1px solid rgba(255, 255, 255, 0.1)',
    },
    whiteText: {
        color: '#ffffff',
    },
    mutedText: {
        color: 'rgba(255, 255, 255, 0.6)',
    },
    input: {
        width: '100%',
        padding: '12px',
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        border: '1px solid rgba(255, 255, 255, 0.2)',
        borderRadius: '6px',
        color: '#fff',
    },
    select: {
        width: '100%',
        padding: '12px',
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        border: '1px solid rgba(255, 255, 255, 0.2)',
        borderRadius: '6px',
        color: '#fff',
        appearance: 'none',
    },
    gradientText: {
        background: 'linear-gradient(45deg, #3b82f6, #8b5cf6)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    }
};

// Interfaces
interface AudioVideoProps {
    history: any;
}

interface VoiceSettings {
    voice: string;
    style: string;
    script: string;
}

interface VideoSettings {
    duration: string;
    style: string;
    cameraMovement: string;
    description: string;
}

const AudioVideo: React.FC<AudioVideoProps> = (props) => {
    const dispatch = useDispatch();
    const state = useSelector((state: State) => state);
    
    // User and authentication state
    const [userData, setUserData] = useState(null);
    const [showGuestAccessPrompt, setShowGuestAccessPrompt] = useState(false);
    const [unreadMsgCount, setUnreadMsgCount] = useState(0);
    const [subsModalVisibility, setSubsModalVisibility] = useState(false);
    const user = cookieService?.getCookie(STRING_CONSTANTS?.cookieStrings?.user);
    const isUserLoggedIn = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.token);
    const { setUnreadNotificationCount } = bindActionCreators(actionCreators, dispatch);

    // Voice Generation state
    const [voiceSettings, setVoiceSettings] = useState<VoiceSettings>({
        voice: 'brendon-clark',
        style: 'professional',
        script: ''
    });
    const [voiceStatus, setVoiceStatus] = useState('');
    const [showVoiceControls, setShowVoiceControls] = useState(false);
    const [voiceBlob, setVoiceBlob] = useState<Blob | null>(null);

    // Video Generation state
    const [videoSettings, setVideoSettings] = useState<VideoSettings>({
        duration: '5',
        style: 'realistic',
        cameraMovement: 'static',
        description: ''
    });
    const [videoStatus, setVideoStatus] = useState('');
    const [showVideoPreview, setShowVideoPreview] = useState(false);
    const [videoBlob, setVideoBlob] = useState<Blob | null>(null);

    // Clip Generation state
    const [selectedCampaign, setSelectedCampaign] = useState('accounts-receivable');
    const [clipCount, setClipCount] = useState('3');
    const [generatingClips, setGeneratingClips] = useState(false);
    const [clipProgress, setClipProgress] = useState(0);
    const [generatedClips, setGeneratedClips] = useState<Array<{ id: string; url: string }>>([]);

    // Effect for initial data loading
    useEffect(() => {
        if (isUserLoggedIn) {
            userService.getUserData()
                .then(res => {
                    cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, res);
                    setUnreadNotificationCount(res?.user?.unreadNotificationCount);
                    setUserData(res);
                })
                .catch(error => {
                    console.error('Error fetching user data:', error);
                    ValidationErrorToast("Error", "Failed to load user data");
                });
        }
    }, [isUserLoggedIn, setUnreadNotificationCount]);

    // Effect for subscription check
    useEffect(() => {
        const checkSubscription = async () => {
            try {
                const res = await dispatch(fetchSubscriptionChose());
                setSubsModalVisibility(Object.keys(res.payload).length === 0);
            } catch (error) {
                console.error("Subscription check error:", error);
                ValidationErrorToast("Error", "Failed to verify subscription status");
            }
        };

        checkSubscription();
    }, [dispatch]);

    // Voice generation handlers
    const handleVoiceSettingChange = (key: keyof VoiceSettings, value: string) => {
        setVoiceSettings(prev => ({
            ...prev,
            [key]: value
        }));
    };

    const handleGenerateVoice = async () => {
        if (!voiceSettings.script) {
            ValidationErrorToast("Error", "Please enter some text first");
            return;
        }

        setVoiceStatus('Generating voice...');
        setShowVoiceControls(false);
        setVoiceBlob(null);

        try {
            const response = await userService.aiRequest({
                method: 'generate-voice',
                data: voiceSettings
            });

            if (response.data?.success) {
                setVoiceBlob(new Blob([response.data.audioData], { type: 'audio/mp3' }));
                setShowVoiceControls(true);
                setVoiceStatus('✨ Voice generated successfully!');
                SuccessToast("Success", "Voice generated successfully");
            } else {
                throw new Error(response.data?.error || 'Unknown error');
            }
        } catch (error) {
            setVoiceStatus('❌ Error generating voice: ' + error.message);
            ValidationErrorToast("Error", error.message);
        }
    };

        // Video generation handlers
        const handleVideoSettingChange = (key: keyof VideoSettings, value: string) => {
            setVideoSettings(prev => ({
                ...prev,
                [key]: value
            }));
        };
    
        const handleGenerateVideo = async () => {
            if (!videoSettings.description) {
                ValidationErrorToast("Error", "Please enter a video description first");
                return;
            }
    
            setVideoStatus('Generating video...');
            setShowVideoPreview(false);
            setVideoBlob(null);
    
            try {
                const response = await userService.aiRequest({
                    method: 'generate-video',
                    data: videoSettings
                });
    
                if (response.data?.success) {
                    setVideoBlob(new Blob([response.data.videoData], { type: 'video/mp4' }));
                    setShowVideoPreview(true);
                    setVideoStatus('✨ Video generated successfully!');
                    SuccessToast("Success", "Video generated successfully");
                } else {
                    throw new Error(response.data?.error || 'Unknown error');
                }
            } catch (error) {
                setVideoStatus('❌ Error generating video: ' + error.message);
                ValidationErrorToast("Error", error.message);
            }
        };
    
        // Component for the Voice Generation Section
        const VoiceGenerationSection = () => (
            <section className="bg-gray-800/30 rounded-xl p-8 mb-8 backdrop-blur-md border border-gray-700">
                <h2 className="text-3xl font-semibold mb-6" style={styles.gradientText}>Voice Generation</h2>
                <div className="space-y-6">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                            <label className="block text-sm font-medium text-blue-300 mb-2">Voice</label>
                            <select 
                                value={voiceSettings.voice}
                                onChange={(e) => handleVoiceSettingChange('voice', e.target.value)}
                                style={styles.select}
                            >
                                <option value="brendon-clark">Brendon Clark (clone)</option>
                            </select>
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-blue-300 mb-2">Style</label>
                            <select 
                                value={voiceSettings.style}
                                onChange={(e) => handleVoiceSettingChange('style', e.target.value)}
                                style={styles.select}
                            >
                                <option value="professional">Professional</option>
                                <option value="casual">Casual</option>
                                <option value="energetic">Energetic</option>
                                <option value="calm">Calm</option>
                            </select>
                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-blue-300 mb-2">Script</label>
                        <textarea 
                            rows={4} 
                            value={voiceSettings.script}
                            onChange={(e) => handleVoiceSettingChange('script', e.target.value)}
                            style={styles.input}
                            placeholder="Enter your script here..."
                        />
                    </div>
                    <div className="flex flex-wrap gap-4">
                        <button 
                            onClick={handleGenerateVoice}
                            className="bg-gradient-to-r from-blue-500 to-purple-600 text-white px-6 py-3 rounded-lg hover:from-blue-600 hover:to-purple-700 transition-all duration-300 flex items-center space-x-2"
                        >
                            <i className="fas fa-microphone" />
                            <span>Generate Voice</span>
                        </button>
                        {showVoiceControls && voiceBlob && (
                            <div className="space-x-4">
                                <button 
                                    onClick={() => {
                                        const audio = new Audio(URL.createObjectURL(voiceBlob));
                                        audio.play();
                                    }}
                                    className="bg-green-500 text-white px-6 py-3 rounded-lg hover:bg-green-600 transition-all duration-300 flex items-center space-x-2"
                                >
                                    <i className="fas fa-play" />
                                    <span>Play</span>
                                </button>
                                <button 
                                    onClick={() => {
                                        const url = URL.createObjectURL(voiceBlob);
                                        const a = document.createElement('a');
                                        a.href = url;
                                        a.download = 'generated-voice.mp3';
                                        document.body.appendChild(a);
                                        a.click();
                                        document.body.removeChild(a);
                                        URL.revokeObjectURL(url);
                                    }}
                                    className="bg-purple-500 text-white px-6 py-3 rounded-lg hover:bg-purple-600 transition-all duration-300 flex items-center space-x-2"
                                >
                                    <i className="fas fa-download" />
                                    <span>Download</span>
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="text-sm text-blue-300">{voiceStatus}</div>
                </div>
            </section>
        );
    
        // Component for Example Audio Section
        const ExampleAudioSection = () => (
            <section className="bg-gray-800/30 rounded-xl p-8 mb-8 backdrop-blur-md border border-gray-700">
                <h2 className="text-3xl font-semibold mb-6" style={styles.gradientText}>Example Audio Output</h2>
                <div className="space-y-4">
                    <div className="p-4 bg-gray-800/50 rounded-lg">
                        <h3 className="text-xl text-blue-300 mb-2">Advertising for Accounts Receivable System (30s)</h3>
                        <audio controls className="w-full">
                            <source src="https://cdn.simulationtheory.ai/gasset/?asset=sound&prompt=professional male voice advertising accounts receivable system" type="audio/mp3" />
                            Your browser does not support the audio element.
                        </audio>
                    </div>
                </div>
            </section>
        );
    // Component for the Video Generation Section
    const VideoGenerationSection = () => (
        <section className="bg-gray-800/30 rounded-xl p-8 mb-8 backdrop-blur-md border border-gray-700">
            <h2 className="text-3xl font-semibold mb-6" style={styles.gradientText}>Video Generation</h2>
            <div className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    <div>
                        <label className="block text-sm font-medium text-blue-300 mb-2">Duration</label>
                        <select 
                            value={videoSettings.duration}
                            onChange={(e) => handleVideoSettingChange('duration', e.target.value)}
                            style={styles.select}
                        >
                            <option value="5">5 seconds</option>
                            <option value="6">6 seconds</option>
                            <option value="7">7 seconds</option>
                            <option value="8">8 seconds</option>
                        </select>
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-blue-300 mb-2">Style</label>
                        <select 
                            value={videoSettings.style}
                            onChange={(e) => handleVideoSettingChange('style', e.target.value)}
                            style={styles.select}
                        >
                            <option value="realistic">Realistic</option>
                            <option value="cartoon">Cartoon</option>
                            <option value="cinematic">Cinematic</option>
                            <option value="artistic">Artistic</option>
                        </select>
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-blue-300 mb-2">Camera Movement</label>
                        <select 
                            value={videoSettings.cameraMovement}
                            onChange={(e) => handleVideoSettingChange('cameraMovement', e.target.value)}
                            style={styles.select}
                        >
                            <option value="static">Static</option>
                            <option value="pan-left">Pan Left</option>
                            <option value="pan-right">Pan Right</option>
                            <option value="zoom-in">Zoom In</option>
                            <option value="zoom-out">Zoom Out</option>
                        </select>
                    </div>
                </div>
                <div>
                    <label className="block text-sm font-medium text-blue-300 mb-2">Description</label>
                    <textarea 
                        rows={4} 
                        value={videoSettings.description}
                        onChange={(e) => handleVideoSettingChange('description', e.target.value)}
                        style={styles.input}
                        placeholder="Describe your video..."
                    />
                </div>
                <button 
                    onClick={handleGenerateVideo}
                    className="bg-gradient-to-r from-blue-500 to-purple-600 text-white px-6 py-3 rounded-lg hover:from-blue-600 hover:to-purple-700 transition-all duration-300 flex items-center space-x-2"
                >
                    <i className="fas fa-video" />
                    <span>Generate Video</span>
                </button>
                {showVideoPreview && videoBlob && (
                    <div className="space-y-4">
                        <h3 className="text-xl font-medium text-blue-300">Preview</h3>
                        <video controls className="w-full rounded-lg bg-gray-800">
                            <source src={URL.createObjectURL(videoBlob)} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <button 
                            onClick={() => {
                                const url = URL.createObjectURL(videoBlob);
                                const a = document.createElement('a');
                                a.href = url;
                                a.download = 'generated-video.mp4';
                                document.body.appendChild(a);
                                a.click();
                                document.body.removeChild(a);
                                URL.revokeObjectURL(url);
                            }}
                            className="bg-purple-500 text-white px-6 py-3 rounded-lg hover:bg-purple-600 transition-all duration-300 flex items-center space-x-2"
                        >
                            <i className="fas fa-download" />
                            <span>Download Video</span>
                        </button>
                    </div>
                )}
                <div className="text-sm text-blue-300">{videoStatus}</div>
            </div>
        </section>
    );

    // Component for Example Video Section
    const ExampleVideoSection = () => (
        <section className="bg-gray-800/30 rounded-xl p-8 mb-8 backdrop-blur-md border border-gray-700">
            <h2 className="text-3xl font-semibold mb-6" style={styles.gradientText}>Example Video Output</h2>
            <div className="space-y-4">
                <div className="p-4 bg-gray-800/50 rounded-lg">
                    <h3 className="text-xl text-blue-300 mb-2">Marketing Campaign Preview</h3>
                    <video controls className="w-full rounded-lg">
                        <source src="https://cdn.simulationtheory.ai/gasset/?asset=video&prompt=professional marketing video with modern office scenes" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </section>
    );

    // Component for Automated Clip Generation Section
    const AutomatedClipSection = () => (
        <section className="bg-gray-800/30 rounded-xl p-8 mb-8 backdrop-blur-md border border-gray-700">
            <h2 className="text-3xl font-semibold mb-6" style={styles.gradientText}>Automated Clip Generation</h2>
            <div className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                        <label className="block text-sm font-medium text-blue-300 mb-2">Select Campaign</label>
                        <select 
                            value={selectedCampaign}
                            onChange={(e) => setSelectedCampaign(e.target.value)}
                            style={styles.select}
                        >
                            <option value="accounts-receivable">Accounts Receivable Marketing Campaign</option>
                            <option value="financial-services">Financial Services Overview</option>
                            <option value="business-solutions">Business Solutions Package</option>
                        </select>
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-blue-300 mb-2">Number of Clips</label>
                        <select 
                            value={clipCount}
                            onChange={(e) => setClipCount(e.target.value)}
                            style={styles.select}
                        >
                            <option value="3">3 Clips</option>
                            <option value="5">5 Clips</option>
                            <option value="10">10 Clips</option>
                        </select>
                    </div>
                </div>
                
                <div className="flex flex-wrap gap-4">
                    <button 
                        onClick={() => {
                            setGeneratingClips(true);
                            setClipProgress(0);
                            // Add your clip generation logic here
                        }}
                        className="bg-gradient-to-r from-blue-500 to-purple-600 text-white px-6 py-3 rounded-lg hover:from-blue-600 hover:to-purple-700 transition-all duration-300 flex items-center space-x-2"
                    >
                        <i className="fas fa-magic" />
                        <span>Generate Clips Automatically</span>
                    </button>
                </div>

                {generatingClips && (
                    <div className="space-y-4">
                        <div className="flex justify-between text-sm text-blue-300">
                            <span>Generating clips...</span>
                            <span>{clipProgress}/{clipCount} complete</span>
                        </div>
                        <div className="w-full bg-gray-700 rounded-full h-2">
                            <div 
                                className="bg-blue-500 rounded-full h-2 transition-all duration-300" 
                                style={{ width: `${(clipProgress / parseInt(clipCount)) * 100}%` }} 
                            />
                        </div>
                    </div>
                )}
            </div>
        </section>
    );

    // Update the return section to match your working layout exactly:
    return (
        <section className="main-dashboard-wrap" style={styles.container}>
            <Helmet>
                <title>MPLOI: Marketing System</title>
            </Helmet>
            <div id="wrapper" style={{ height: "100vh" }}>
                {/* Sidebar */}
                <ul className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ${state.common.showSidebarOnMobileView ? "active" : ""}`} id="accordionSidebar">
                    <div>
                        <Link 
                            style={{ pointerEvents: "none" }} 
                            className="sidebar-brand d-flex align-items-center justify-content-center" 
                            to="#"
                        >
                            <img src={UtilityFunctions.getImageURL() + "db-logo.svg"} alt="Logo" />
                        </Link>
                        {!user?.user?.isAccountsSignup && (
                            <li className="nav-item">
                                <Link className="nav-link" to="/dashboard/home">
                                    <img src={UtilityFunctions.getImageURL() + "home-icon-blank-white.svg"} alt="" />
                                    <img src={UtilityFunctions.getImageURL() + "home-icon-fill-green.svg"} alt="" />
                                </Link>
                            </li>
                        )}
                        {user?.user?.isAccountsSignup && (
                            <li className="nav-item">
                                <Link className="nav-link" to="/ar">
                                    <img src={UtilityFunctions.getImageURL() + "home-icon-blank-white.svg"} alt="" />
                                    <img src={UtilityFunctions.getImageURL() + "home-icon-fill-green.svg"} alt="" />
                                </Link>
                            </li>
                        )}
    
                        {!user?.user?.isAccountsSignup && (
                            <li className="nav-item notification-nav">
                                <Link className="nav-link" to="/dashboard/chat/">
                                    <div className="svg-box">
                                        <img src={UtilityFunctions.getImageURL() + "chat-icon.svg"} alt="" />
                                        <img src={UtilityFunctions.getImageURL() + "chat-icon-fill-green.svg"} alt="" />
                                        {unreadMsgCount ? (
                                            <span>{unreadMsgCount > 9 ? "9+" : unreadMsgCount}</span>
                                        ) : ""}
                                    </div>
                                </Link>
                            </li>
                        )}
                    </div>
    
                    <div>
                        <li className="nav-item">
                            <UpgradePlanCheck
                                isUserLoggedIn={isUserLoggedIn}
                                setShowGuestAccessPrompt={setShowGuestAccessPrompt}
                                userData={userData}
                            />
                        </li>
                    </div>
    
                    <div>
                        <li className="nav-item notification-nav">
                            <span 
                                className="nav-link"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    isUserLoggedIn
                                        ? props.history.push(STRING_CONSTANTS.navigationString.dashboard.notifications)
                                        : setShowGuestAccessPrompt(true)
                                }}
                            >
                                <div className="svg-box">
                                    <img src={UtilityFunctions.getImageURL() + "bell-icon-blank-white.svg"} alt="" />
                                    <img src={UtilityFunctions.getImageURL() + "bell-icon-fill-green.svg"} alt="" />
                                    {state?.unreadNotificationCount?.unreadNotificationCount ? (
                                        <span>
                                            {state?.unreadNotificationCount?.unreadNotificationCount > 9
                                                ? "9+"
                                                : state?.unreadNotificationCount?.unreadNotificationCount}
                                        </span>
                                    ) : ""}
                                </div>
                            </span>
                        </li>
                    </div>
                </ul>
    
                {/* Content Wrapper */}
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        {/* Top Bar */}
                        <div style={styles.topBar}>
                            <h1 style={styles.topBarTitle}>Marketing System</h1>
                            <div style={styles.buttonGroup}>
                                <button 
                                    style={{...styles.button, ...styles.secondaryButton}}
                                    onClick={() => props.history.push('/marketing/settings')}
                                >
                                    <i className="fas fa-cog" />
                                    Settings
                                </button>
                            </div>
                        </div>
    
                        {/* Main Content */}
                        <div className="container-fluid">
                            <main className="max-w-7xl mx-auto px-4 py-8 space-y-8">
                                <VoiceGenerationSection />
                                <ExampleAudioSection />
                                <VideoGenerationSection />
                                <ExampleVideoSection />
                                <AutomatedClipSection />
                            </main>
                        </div>
                    </div>
                </div>
            </div>
    
            {/* Subscription Modal */}
            {/* <SubscriptionModalAccounts
                subsModal={subsModalVisibility}
                setSubsModal={setSubsModalVisibility}
                history={props.history}
            /> */}
        </section>
    );
};

export default AudioVideo;
