import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { UtilityFunctions } from '../../utils/UtilityFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom'; 
import { api } from '../../middleware/api';
import { bindActionCreators } from "redux";
import { actionCreators, State } from "../../store";
import { cookieService, userService } from '../../_services';
import UpgradePlanCheck from "../Dashboard/Home/upgradePlanCheck";
import { STRING_CONSTANTS } from '../../utils/constants/stringConstants';
import ValidationErrorToast from '../../components/validationErrorToast';
import SuccessToast from '../../components/successToast';
import moment from 'moment';

const sampleInteractions = [];

// Styles object (extending from previous pages)
const styles = {
    backButton: {
        backgroundColor: 'transparent',
        border: '1px solid rgba(255, 255, 255, 0.2)',
        color: '#fff',
        padding: '8px 16px',
        borderRadius: '4px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        fontSize: '14px',
        transition: 'all 0.2s ease',
        ':hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.05)',
            borderColor: 'rgba(255, 255, 255, 0.3)',
        }
    },
    container: {
        backgroundColor: '#1a1a2e',
        minHeight: '100vh',
    },
    blueBox: {
        background: 'rgba(255, 255, 255, 0.05)',
        borderRadius: '8px',
        padding: '20px',
        marginBottom: '20px',
    },
    whiteText: {
        color: '#ffffff',
    },
    mutedText: {
        color: 'rgba(255, 255, 255, 0.6)',
    },
    statsCard: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    iconBox: {
        background: 'rgba(255, 255, 255, 0.1)',
        borderRadius: '8px',
        padding: '12px',
        marginRight: '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    timeline: {
        position: 'relative',
        padding: '20px 0',
    },
    timelineItem: {
        position: 'relative',
        padding: '20px 0 20px 40px',
        borderLeft: '2px solid rgba(255, 255, 255, 0.1)',
    },
    timelineDot: {
        position: 'absolute',
        left: '-6px',
        top: '24px',
        width: '10px',
        height: '10px',
        borderRadius: '50%',
    },
    button: {
        padding: '8px 16px',
        borderRadius: '4px',
        border: 'none',
        marginRight: '8px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        color: '#fff',
    },
    buttonPrimary: {
        backgroundColor: '#007bff',
    },
    buttonDanger: {
        backgroundColor: '#dc3545',
    },
    buttonSuccess: {
        backgroundColor: '#28a745',
    },
    badge: {
        padding: '6px 12px',
        borderRadius: '4px',
        fontWeight: '500',
        display: 'inline-block',
    },
    transcriptBox: {
        background: 'rgba(0, 0, 0, 0.2)',
        borderRadius: '4px',
        padding: '15px',
        marginTop: '10px',
        fontFamily: 'monospace',
        whiteSpace: 'pre-wrap',
        maxHeight: '200px',
        overflowY: 'auto',
        color: '#ffffff',  // Add this for white text
        lineHeight: '1.5', // Add this for better readability
        fontSize: '14px',  // Add this for better size
        border: '1px solid rgba(255, 255, 255, 0.1)', // Add subtle border
    },
    audioPlayer: {
        width: '100%',
        marginTop: '10px',
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        borderRadius: '4px',
    },
    table: {
        width: '100%',
        color: '#fff',
        borderCollapse: 'separate',
        borderSpacing: '0 8px',
    },
    tableHeader: {
        color: 'rgba(255, 255, 255, 0.6)',
        fontWeight: '500',
        padding: '15px',
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
        textAlign: 'left',
    },
    tableCell: {
        padding: '15px',
        borderTop: '1px solid rgba(255, 255, 255, 0.1)',
        color: '#fff',
    },
};

const audioStyles = {
    ...styles,
    progressContainer: {
        width: '200px',
        height: '4px',
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        borderRadius: '2px',
        margin: '0 10px',
        cursor: 'pointer',
    },
    progressBar: {
        height: '100%',
        backgroundColor: '#007bff',
        borderRadius: '2px',
        transition: 'width 0.1s linear',
    },
    audioControls: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '10px',
        gap: '10px',
    },
    timeText: {
        color: 'rgba(255, 255, 255, 0.6)',
        fontSize: '12px',
        minWidth: '50px',
    },
    controlButton: {
        ...styles.button,
        padding: '6px 12px',
        minWidth: '100px', // Add this to make all buttons the same width
        display: 'flex',
        justifyContent: 'center', // Center the text/emoji
        alignItems: 'center',
        gap: '6px',
    }
};

const CustomerProfile = (props) => {
    const { organizationId, contactId } = props.match.params;
    const dispatch = useDispatch();
    const [userData, setUserData] = useState<any>();
    const { setUnreadNotificationCount } = bindActionCreators(actionCreators, dispatch);
    const [showGuestAccessPrompt, setShowGuestAccessPrompt] = useState(false);
    
    // Customer Data States
    const [customer, setCustomer] = useState({});
    const [outstandingInvoices, setOutstandingInvoices] = useState([]);
    const [financialSummary, setFinancialSummary] = useState({});
    const [interactionStats, setInteractionStats] = useState({});
    const [interactions, setInteractions] = useState(sampleInteractions);
    const [loading, setLoading] = useState(true);
    const [activeTranscript, setActiveTranscript] = useState(null);


    const [playingInteractionId, setPlayingInteractionId] = useState(null);
    const [audioProgress, setAudioProgress] = useState(0);
    const [audioDuration, setAudioDuration] = useState(0);
    const [currentAudio, setCurrentAudio] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);

    // StatsOutstanding Invoices
    const [stats, setStats] = useState({
        totalCalls: 0,
        successfulCalls: 0,
        totalDuration: 0,
        averageCallDuration: 0,
        totalCollected: 0,
        remainingAmount: 0,
        lastPaymentDate: null,
        nextScheduledCall: null
    });

    const state = useSelector((state: State) => state);
    const [unreadMsgCount, setUnreadMsgCount] = useState(0);
    const user = cookieService?.getCookie(STRING_CONSTANTS?.cookieStrings?.user);
    const isUserLoggedIn = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.token);

    useEffect(() => {
        if (isUserLoggedIn) {
            userService.getUserData()
                .then(res => {
                    cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, res)
                    setUnreadNotificationCount(res?.user?.unreadNotificationCount)
                    setUserData(res)
                })
        }
    }, []);

    useEffect(() => {
        fetchCustomerData();
    }, [contactId]);

    const fetchCustomerData = async () => {

        setLoading(true);
        const params = {
            'method': 'get-customer-data',
            data: {organizationId, contactId},
        }

        userService.aiRequest(params, true)
        .then(data => {
            setCustomer(data.data.customerInfo);
            setOutstandingInvoices(data.data.outstandingInvoices);
            setFinancialSummary(data.data.financialSummary);
            setInteractionStats(data.data.interactionStats)
        })
        .catch(e => console.log(e))
    };

    useEffect(() => {
        const payments = interactions.filter(i => i.type === 'payment');
        const totalCollected = financialSummary?.collectedAmount || 0;
        const amountOwing = financialSummary?.outstandingAmount || 0;
        const successfulCalls = interactionStats?.totalCalls || 0;

        setStats({
            totalCalls: successfulCalls,
            successfulCalls: successfulCalls,
            totalCollected: totalCollected,
            remainingAmount: amountOwing,
            lastPaymentDate: payments.length ? payments[0].timestamp : null,
            nextScheduledCall: null // Would be set from API
        });
    }, [financialSummary, interactionStats]);


    useEffect(() => {
        const fetchInteractions = async () => {
            try {
                const response = await userService.aiRequest({
                    method: 'get-customer-interactions',
                    data: { contactId, organizationId },
                });

                // We can also tell if they are disabled here and map that.
                if (response.data.interactions.interactions) {
                    setInteractions(response.data.interactions.interactions.map(interaction => ({
                        id: interaction.id,
                        type: interaction.type,
                        direction: interaction.direction,
                        timestamp: interaction.timestamp,
                        duration: interaction.duration,
                        outcome: interaction.outcome,
                        summary: interaction.summary,
                        recordingUrl: interaction.recordingUrl,
                        transcript: interaction.transcript,
                        amount: interaction.amount,
                        method: interaction.method,
                        reference: interaction.reference,
                        customer: {
                            name: interaction.customerName,
                            email: interaction.customerEmail,
                            phone: interaction.customerPhone
                        }
                    })));
                }
            } catch (error) {
                console.error('Error fetching interactions:', error);
            }
        };
    
        fetchInteractions();
    }, [contactId]);

    const initiateCall = async (organizationId, contactId, invoiceId) => {
        try {
            const response = await userService.aiRequest({
                method: 'initiate-call',
                data: {
                    organizationId,
                    contactId,
                    invoiceId,
                }
            }, true);

            if (response.data?.success) {
                SuccessToast("Success", "Call successfully initiated.");
            } else {
                ValidationErrorToast("Error", `Call could not be initiated: ${response.data?.error || 'Unknown error'}`);
            }
        } catch (error) {
            ValidationErrorToast("Error", `Call could not be initiated: ${error.message || 'Unknown error'}`);
        }
    };

    // Add this new function to handle seeking in the audio
    const handleSeek = (e, interactionId) => {
        if (currentAudio && currentAudio.id === interactionId) {
            const container = e.currentTarget;
            const bounds = container.getBoundingClientRect();
            const x = e.clientX - bounds.left;
            const percentage = x / bounds.width;
            currentAudio.currentTime = percentage * audioDuration;
            setAudioProgress(currentAudio.currentTime);
        }
    };

    const handleStopAudio = () => {
        if (currentAudio) {
            currentAudio.pause();
            currentAudio.currentTime = 0;
            setCurrentAudio(null);
            setIsPlaying(false);
            setAudioProgress(0);
            setPlayingInteractionId(null);
        }
    };
    
    const handlePauseAudio = () => {
        if (currentAudio) {
            if (isPlaying) {
                currentAudio.pause();
            } else {
                currentAudio.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    // Update the handlePlayRecording function
    const handlePlayRecording = async (recordingUrl, interactionId) => {
        try {
            // If there's already audio playing, stop it
            if (currentAudio) {
                handleStopAudio();
            }
    
            const audio = new Audio(recordingUrl);
            audio.id = interactionId;
            setCurrentAudio(audio);
            setPlayingInteractionId(interactionId);
            
            // Add event listeners
            audio.addEventListener('timeupdate', () => {
                setAudioProgress(audio.currentTime);
            });
    
            audio.addEventListener('loadedmetadata', () => {
                setAudioDuration(audio.duration);
            });
    
            audio.addEventListener('ended', () => {
                setIsPlaying(false);
                setAudioProgress(0);
                setPlayingInteractionId(null);
            });
    
            await audio.play();
            setIsPlaying(true);
        } catch (error) {
            console.error("Play error:", error);
            ValidationErrorToast("Error", "Failed to play recording");
        }
    };

    const handlePauseCollection = async () => {
        try {
            const params = {
                'method': 'pause-customer',
                data: {organizationId, contactId},
            }
            const response = await userService.aiRequest(params, true);
            SuccessToast("Success", "Collection paused for this customer");
            await fetchCustomerData(); // Refresh the customer data
        } catch (error) {
            console.log(error);
            ValidationErrorToast("Error", "Failed to pause collection");
        }
    };
    
    const handleResumeCollection = async () => {
        try {
            const params = {
                'method': 'resume-customer',
                data: {organizationId, contactId},
            }
            const response = await userService.aiRequest(params, true);
            SuccessToast("Success", "Collection resumed for this customer");
            await fetchCustomerData(); // Refresh the customer data
        } catch (error) {
            console.log(error);
            ValidationErrorToast("Error", "Failed to resume collection");
        }
    };

    const capitalizeFirst = (str) => {
        if (!str) return '';
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    const formatDuration = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    const getOutcomeColor = (outcome) => {
        switch (outcome || '') {
            case 'positive':
                return '#28a745';
            case 'neutral':
                return '#ffc107';
            case 'negative':
                return '#dc3545';
            default:
                return '#6c757d';
        }
    };

    return (
        <section className="main-dashboard-wrap" style={styles.container}>
            <Helmet>
                <title>MPLOI: Customer Profile</title>
            </Helmet>
            <div id="wrapper" style={{ height: "100vh" }}>
                {/* Full Sidebar */}
                <ul className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ${state.common.showSidebarOnMobileView ? "active" : ""}`} id="accordionSidebar">
                    <div>
                        <Link style={{ pointerEvents: "none" }} className="sidebar-brand d-flex align-items-center justify-content-center" to="#">
                            <img src={UtilityFunctions.getImageURL() + "db-logo.svg"} />
                        </Link>
                        {!user?.user?.isAccountsSignup && <li className="nav-item">
                            <Link className="nav-link" to="/dashboard/home">
                                <img src={UtilityFunctions.getImageURL() + "home-icon-blank-white.svg"} alt="" />
                                <img src={UtilityFunctions.getImageURL() + "home-icon-fill-green.svg"} alt="" />
                            </Link>
                        </li>}
                        {user?.user?.isAccountsSignup && <li className="nav-item">
                            <Link className="nav-link" to="/ar">
                                <img src={UtilityFunctions.getImageURL() + "home-icon-blank-white.svg"} alt="" />
                                <img src={UtilityFunctions.getImageURL() + "home-icon-fill-green.svg"} alt="" />
                            </Link>
                        </li>}

                        {!user?.user?.isAccountsSignup && <li className="nav-item notification-nav">
                            <Link className="nav-link" to="/dashboard/chat/">
                                <div className="svg-box">
                                    <img src={UtilityFunctions.getImageURL() + "chat-icon.svg"} alt="" />
                                    <img src={UtilityFunctions.getImageURL() + "chat-icon-fill-green.svg"} alt="" />
                                    {unreadMsgCount ? <span >{unreadMsgCount > 9 ? "9+" : unreadMsgCount}</span> : ""}
                                </div>
                            </Link>
                        </li>}


                        {false && user?.user?.type === STRING_CONSTANTS.userType.employer && <li className="nav-item">
                            <Link className="nav-link" to="/dashboard/modules">
                                <img src={UtilityFunctions.getImageURL() + "employeesLandingPage/exploreFifth.svg"} 
                                    style={{ filter: 'brightness(0) invert(1)' }} alt="" />
                                <img src={UtilityFunctions.getImageURL() + "employeesLandingPage/exploreFifth.svg"} 
                                    style={{ filter: 'brightness(0) invert(1)' }} alt="" />
                            </Link>
                        </li>}
                    </div>
                    <div>
                        <li className="nav-item">
                            <UpgradePlanCheck
                                isUserLoggedIn={isUserLoggedIn}
                                setShowGuestAccessPrompt={setShowGuestAccessPrompt}
                                userData={userData}
                            />
                        </li>
                    </div>
                    <div>
                        <li className="nav-item notification-nav">
                            <span className="nav-link"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    isUserLoggedIn ?
                                        props.history.push(STRING_CONSTANTS.navigationString.dashboard.notifications) :
                                        setShowGuestAccessPrompt(true)
                                }}
                            >
                                <div className="svg-box">
                                    <img src={UtilityFunctions.getImageURL() + "bell-icon-blank-white.svg"} alt="" />
                                    <img src={UtilityFunctions.getImageURL() + "bell-icon-fill-green.svg"} alt="" />
                                    {state?.unreadNotificationCount?.unreadNotificationCount ? 
                                        <span>
                                            {state?.unreadNotificationCount?.unreadNotificationCount > 9 ? 
                                                "9+" : state?.unreadNotificationCount?.unreadNotificationCount}
                                        </span> : ""}
                                </div>
                            </span>
                        </li>
                        <li className="nav-item">
                            <span className="nav-link"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    isUserLoggedIn ?
                                        props.history.push(STRING_CONSTANTS.navigationString.dashboard.settings) :
                                        setShowGuestAccessPrompt(true)
                                }}
                            >
                                <img src={UtilityFunctions.getImageURL() + "settings-icon-blank-white.svg"} alt="" />
                                <img src={UtilityFunctions.getImageURL() + "settings-icon-fill-green.svg"} alt="" />
                            </span>
                        </li>
                        <li className='profile-pic d-grid justify-content-center gap-2 text-white'>
                            <img src={
                                userData?.user?.type === STRING_CONSTANTS.userType.employer ? 
                                    (userData?.logo || UtilityFunctions.getImageURL() + "profile-pic.png") : 
                                    (userData?.profilePhoto || UtilityFunctions.getImageURL() + "default-employee-profile-pic-round.svg")
                            } />
                            {!isUserLoggedIn && 
                                <span style={{ cursor: "pointer" }}
                                    onClick={() => props.history.push({
                                        pathname: STRING_CONSTANTS.navigationString.signup,
                                        state: { isGuest: true }
                                    })}
                                >
                                    Signup
                                </span>
                            }
                        </li>
                    </div>
                </ul>

                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <div className="container-fluid">
                            {/* Back Button */}
                            <div style={{marginBottom: '24px', marginTop: '12px'}}>
                                <button 
                                    style={styles.backButton}
                                    onClick={() => props.history.push('/ar')}
                                >
                                    <span style={{fontSize: '18px', lineHeight: '1'}}>←</span> 
                                    <span>Back</span>
                                </button>
                            </div>

                            {/* Header Section */}
                            <div className='title-row d-flex justify-content-between align-items-center'>
                                <div>
                                    <h1 style={styles.whiteText}>{customer.name}</h1>
                                    <p style={styles.mutedText}>{customer.phone||"No phone number available"}</p>
                                </div>
                                <div style={{display: 'flex', gap: '10px'}}>
                                    {stats.remainingAmount > 0 && (
                                        <button 
                                            style={{...styles.button, ...styles.buttonPrimary}}
                                            onClick={() => initiateCall(organizationId, contactId)}
                                        >
                                            Call Now
                                        </button>
                                    )}
                                    <button 
                                        style={{
                                            ...styles.button,
                                            ...(customer.collectionsDisabled ? styles.buttonSuccess : styles.buttonDanger)
                                        }}
                                        onClick={customer.collectionsDisabled ? handleResumeCollection : handlePauseCollection}
                                    >
                                        {customer.collectionsDisabled ? 'Start Collection' : 'Pause Collection'}
                                    </button>
                                </div>
                            </div>

                            <div className="scroll-bar-dash-full margn-top38">
                                <div className="row">
                                    {/* Stats Cards */}
                                    <div className="col-xl-12">
                                        <div className="row mb-4">
                                        {[
                                            {
                                                label: "Outstanding",
                                                value: `$${stats.remainingAmount.toLocaleString()}`,
                                                // icon: "💰",
                                                color: "#dc3545"
                                            },
                                            {
                                                label: "Collected",
                                                value: `$${stats.totalCollected.toLocaleString()}`,
                                                // icon: "✅",
                                                color: "#28a745"
                                            },
                                            {
                                                label: "Calls",
                                                value: `${stats.totalCalls}`,
                                                // icon: "📞",
                                                color: "#17a2b8"
                                            },
                                        ].map((stat, index) => (
                                            <div key={index} className="col-xl-3 col-md-6">
                                                <div style={styles.blueBox}>
                                                    <div style={styles.statsCard}>
                                                        <div style={styles.statsContent}>
                                                            <h4 style={styles.mutedText}>{stat.label}</h4>
                                                            <h2 style={styles.whiteText}>{stat.value}</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        </div>

                                        {/* Outstanding Invoices Section */}
                                        <div style={styles.blueBox}>
                                            <h3 style={{...styles.whiteText, marginBottom: '20px'}}>
                                                Outstanding Invoices
                                            </h3>
                                            <div className="table-responsive">
                                                <table style={styles.table}>
                                                    <thead>
                                                        <tr>
                                                            <th style={styles.tableHeader}>Invoice #</th>
                                                            <th style={styles.tableHeader}>Amount Due</th>
                                                            <th style={styles.tableHeader}>Due Date</th>
                                                            <th style={styles.tableHeader}>Days Overdue</th>
                                                            <th style={styles.tableHeader}>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {outstandingInvoices?.map((invoice) => (
                                                            <tr key={invoice.id}>
                                                                <td style={styles.tableCell}>{invoice.invoiceNumber}</td>
                                                                <td style={styles.tableCell}>${invoice.amountDue?.toLocaleString()}</td>
                                                                <td style={styles.tableCell}>
                                                                    {moment(invoice.dueDate).format('DD/MM/YYYY')}
                                                                </td>
                                                                <td style={styles.tableCell}>
                                                                    <span style={{
                                                                        ...styles.badge,
                                                                        backgroundColor: invoice.daysOverdue > 90 ? '#dc3545' :
                                                                                    invoice.daysOverdue > 30 ? '#ffc107' :
                                                                                    invoice.daysOverdue > 0 ? '#17a2b8' : 
                                                                                    '#28a745'
                                                                    }}>
                                                                        {invoice.daysOverdue > 0 ? 
                                                                            `${invoice.daysOverdue} days` : 
                                                                            'Current'}
                                                                    </span>
                                                                </td>
                                                                <td style={styles.tableCell}>
                                                                    <button 
                                                                        style={{...styles.button, ...styles.buttonPrimary}}
                                                                        onClick={() => initiateCall(organizationId, contactId, invoice.invoiceId)}
                                                                    >
                                                                        Call Now
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        {/* Payment Plan Info */}
                                        {false && customer.paymentPlan?.active && (
                                            <div style={styles.blueBox} className="mb-4">
                                                <h3 style={{...styles.whiteText, marginBottom: '20px'}}>
                                                    <img 
                                                        src={UtilityFunctions.getImageURL() + "calendar-icon-green.svg"}
                                                        style={{marginRight: '10px'}}
                                                        alt=""
                                                    />
                                                    Active Payment Plan
                                                </h3>
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <p style={styles.mutedText}>Start Date</p>
                                                        <p style={styles.whiteText}>
                                                            {moment(customer.paymentPlan.startDate).format('DD/MM/YYYY')}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <p style={styles.mutedText}>Installment Amount</p>
                                                        <p style={styles.whiteText}>
                                                            ${customer.paymentPlan.installmentAmount.toLocaleString()}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <p style={styles.mutedText}>Frequency</p>
                                                        <p style={styles.whiteText}>{customer.paymentPlan.frequency}</p>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <p style={styles.mutedText}>Next Payment</p>
                                                        <p style={styles.whiteText}>
                                                            {moment(customer.paymentPlan.nextPayment).format('DD/MM/YYYY')}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {/* Interaction Timeline */}
                                        <div style={styles.blueBox}>
                                            <h3 style={{...styles.whiteText, marginBottom: '20px'}}>
                                                <img 
                                                    src={UtilityFunctions.getImageURL() + "history-icon-green.svg"}
                                                    style={{marginRight: '10px'}}
                                                    alt=""
                                                />
                                                Interaction History
                                            </h3>
                                            <div style={styles.timeline}>
                                                {interactions.map((interaction, index) => (
                                                    <div key={interaction.id} style={styles.timelineItem}>
                                                        <div style={{
                                                            ...styles.timelineDot,
                                                            backgroundColor: interaction.type === 'call' 
                                                                ? getOutcomeColor(interaction.outcome)
                                                                : '#28a745'
                                                        }} />
                                                        
                                                        <div style={{marginBottom: '15px'}}>
                                                            <div style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'flex-start',
                                                                marginBottom: '10px'
                                                            }}>
                                                                <div>
                                                                    <h4 style={styles.whiteText}>
                                                                        {interaction.type === 'call' ? (
                                                                            <>
                                                                                {interaction.direction === 'outbound' ? '📤' : '📥'} &nbsp;
                                                                                {interaction.direction === 'outbound' ? 'Outbound Call' : 'Inbound Call'}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                💵 &nbsp;Payment Received
                                                                            </>
                                                                        )}
                                                                    </h4>
                                                                    <p style={styles.mutedText}>
                                                                        {moment(interaction.timestamp).format('DD/MM/YYYY HH:mm')}
                                                                    </p>
                                                                </div>
                                                                {interaction.type === 'call' && (
                                                                    <span style={{
                                                                        ...styles.badge,
                                                                        backgroundColor: getOutcomeColor(interaction.outcome)
                                                                    }}>
                                                                        {capitalizeFirst(interaction.outcome) || 'Neutral'}
                                                                    </span>
                                                                )}
                                                            </div>

                                                            {interaction.type === 'call' ? (
                                                                <>
                                                                    <p style={styles.whiteText}>
                                                                        Duration: {formatDuration(interaction.duration)}
                                                                    </p>
                                                                    <p style={styles.mutedText}>
                                                                        {interaction.summary}
                                                                    </p>
                                                                    
                                                                    {/* Audio Player */}
                                                                    <div style={audioStyles.audioControls}>
                                                                        {/* Play button */}
                                                                        {interaction.recordingUrl && playingInteractionId !== interaction.id && (
                                                                            <button
                                                                                style={{
                                                                                    ...audioStyles.controlButton,
                                                                                    ...styles.buttonPrimary,
                                                                                }}
                                                                                onClick={() => handlePlayRecording(interaction.recordingUrl, interaction.id)}
                                                                            >
                                                                                ▶️ Play
                                                                            </button>
                                                                        )}
                                                                        
                                                                        {/* Controls for playing interaction */}
                                                                        {playingInteractionId === interaction.id && (
                                                                            <>
                                                                                <button
                                                                                    style={{
                                                                                        ...audioStyles.controlButton,
                                                                                        backgroundColor: isPlaying ? '#ffc107' : '#007bff',
                                                                                    }}
                                                                                    onClick={handlePauseAudio}
                                                                                >
                                                                                    {isPlaying ? '⏸️ Pause' : '▶️ Resume'}
                                                                                </button>
                                                                                
                                                                                <button
                                                                                    style={{
                                                                                        ...audioStyles.controlButton,
                                                                                        backgroundColor: '#dc3545',
                                                                                    }}
                                                                                    onClick={handleStopAudio}
                                                                                >
                                                                                    ⏹️ Stop
                                                                                </button>

                                                                                {/* Progress bar */}
                                                                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px', flex: 1 }}>
                                                                                    <span style={audioStyles.timeText}>
                                                                                        {formatDuration(Math.floor(audioProgress))}
                                                                                    </span>
                                                                                    <div 
                                                                                        style={{
                                                                                            ...audioStyles.progressContainer,
                                                                                            flex: 1,
                                                                                        }}
                                                                                        onClick={(e) => handleSeek(e, interaction.id)}
                                                                                    >
                                                                                        <div 
                                                                                            style={{
                                                                                                ...audioStyles.progressBar,
                                                                                                width: `${(audioProgress / audioDuration) * 100}%`
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <span style={audioStyles.timeText}>
                                                                                        {formatDuration(Math.floor(audioDuration))}
                                                                                    </span>
                                                                                </div>
                                                                            </>
                                                                        )}

                                                                        {/* Transcript button */}
                                                                        <button
                                                                            style={{
                                                                                ...audioStyles.controlButton,
                                                                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                                                            }}
                                                                            onClick={() => setActiveTranscript(
                                                                                activeTranscript === interaction.id 
                                                                                    ? null 
                                                                                    : interaction.id
                                                                            )}
                                                                        >
                                                                            📄 {activeTranscript === interaction.id ? 'Hide Transcript' : 'View Transcript'}
                                                                        </button>
                                                                    </div>

                                                                    {/* Transcript */}
                                                                    {activeTranscript === interaction.id && (
                                                                        <div style={styles.transcriptBox}>
                                                                            {interaction.transcript}
                                                                        </div>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <p style={styles.whiteText}>
                                                                        Amount: ${interaction.amount.toLocaleString()}
                                                                    </p>
                                                                    <p style={styles.mutedText}>
                                                                        Method: {interaction.method}
                                                                    </p>
                                                                    {/* <p style={styles.mutedText}>
                                                                        Reference: {interaction.reference}
                                                                    </p> */}
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default withRouter(CustomerProfile);
