import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { UtilityFunctions } from '../../utils/UtilityFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from "redux";
import { actionCreators, State } from "../../store";
import { cookieService, userService } from '../../_services';
import UpgradePlanCheck from "../Dashboard/Home/upgradePlanCheck";
import { STRING_CONSTANTS } from '../../utils/constants/stringConstants';
import ValidationErrorToast from '../../components/validationErrorToast';
import SuccessToast from '../../components/successToast';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';

// Styles object
const styles = {
    container: {
        backgroundColor: '#1a1a2e',
        minHeight: '100vh',
    },
    topBar: {
        background: 'rgba(255, 255, 255, 0.05)',
        padding: '16px 24px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '24px',
    },
    topBarTitle: {
        color: '#ffffff',
        margin: 0,
        fontSize: '24px',
        fontWeight: '500',
    },
    blueBox: {
        background: 'rgba(255, 255, 255, 0.05)',
        borderRadius: '8px',
        padding: '20px',
        marginBottom: '20px',
    },
    whiteText: {
        color: '#ffffff',
    },
    mutedText: {
        color: 'rgba(255, 255, 255, 0.6)',
    },
    affiliateCodeBox: {
        background: 'linear-gradient(135deg, rgba(6, 239, 197, 0.2) 0%, rgba(0, 123, 255, 0.2) 100%)',
        borderRadius: '12px',
        padding: '30px',
        textAlign: 'center',
        marginBottom: '24px',
        border: '1px solid rgba(6, 239, 197, 0.3)',
    },
    affiliateCode: {
        fontSize: '36px',
        fontWeight: '700',
        color: '#06EFC5',
        letterSpacing: '3px',
        margin: '10px 0',
    },
    copyButton: {
        backgroundColor: 'rgba(6, 239, 197, 0.1)',
        border: '1px solid #06EFC5',
        color: '#06EFC5',
        padding: '8px 16px',
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: '14px',
        marginLeft: '15px',
        transition: 'all 0.3s ease',
    },
    urlBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        borderRadius: '6px',
        padding: '12px 20px',
        marginTop: '20px',
    },
    urlText: {
        color: '#ffffff',
        fontSize: '16px',
        marginRight: '10px',
        fontFamily: 'monospace',
    },
    table: {
        width: '100%',
        color: '#fff',
        borderCollapse: 'separate',
        borderSpacing: '0 8px',
    },
    tableHeader: {
        color: 'rgba(255, 255, 255, 0.6)',
        fontWeight: '500',
        padding: '15px',
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
        textAlign: 'left',
    },
    tableCell: {
        padding: '15px',
        borderTop: '1px solid rgba(255, 255, 255, 0.1)',
        color: '#fff',
    },
    badge: {
        padding: '6px 12px',
        borderRadius: '4px',
        fontWeight: '500',
        display: 'inline-block',
    },
    pendingBadge: {
        backgroundColor: 'rgba(255, 193, 7, 0.2)',
        color: '#ffc107',
    },
    confirmedBadge: {
        backgroundColor: 'rgba(40, 167, 69, 0.2)',
        color: '#28a745',
    },
    button: {
        padding: '8px 16px',
        borderRadius: '4px',
        border: 'none',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        color: '#fff',
    },
    primaryButton: {
        backgroundColor: '#007bff',
    },
    marketingItem: {
        padding: '15px',
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    marketingItemTitle: {
        color: '#ffffff',
        fontSize: '16px',
        fontWeight: '500',
    },
    marketingItemDescription: {
        color: 'rgba(255, 255, 255, 0.6)',
        fontSize: '14px',
        marginTop: '5px',
    },
    infoBox: {
        backgroundColor: 'rgba(0, 123, 255, 0.1)',
        border: '1px solid rgba(0, 123, 255, 0.3)',
        borderRadius: '8px',
        padding: '20px',
        marginBottom: '20px',
    },
    infoTitle: {
        color: '#ffffff',
        fontSize: '18px',
        marginBottom: '15px',
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
    },
    infoPoint: {
        display: 'flex',
        marginBottom: '10px',
    },
    infoIcon: {
        color: '#06EFC5',
        marginRight: '10px',
        fontSize: '16px',
    },
    infoText: {
        color: 'rgba(255, 255, 255, 0.8)',
    },
    statsCard: {
        textAlign: 'center',
        padding: '20px',
    },
    statsNumber: {
        fontSize: '36px',
        fontWeight: '700',
        color: '#06EFC5',
        marginBottom: '5px',
    },
    statsLabel: {
        color: 'rgba(255, 255, 255, 0.6)',
        fontSize: '14px',
    },
    contactButton: {
        backgroundColor: 'rgba(6, 239, 197, 0.1)',
        border: '1px solid #06EFC5',
        color: '#06EFC5',
        padding: '8px 16px',
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: '14px',
        transition: 'all 0.3s ease',
    },
};

// Sample data for referrals
const SAMPLE_REFERRALS = [
    {
        id: 1,
        status: 'CONFIRMED',
        dateReferred: '2023-10-15T14:30:00',
        companyName: 'TechSolutions Inc.',
        contactName: 'Sarah Johnson',
        email: 'sarah@techsolutions.com',
        phone: '+61 4 1234 5678',
        commission: 250.00
    },
    {
        id: 2,
        status: 'PENDING',
        dateReferred: '2023-11-02T09:15:00',
        companyName: 'Global Logistics Ltd.',
        contactName: 'Michael Chen',
        email: 'mchen@globallogistics.com',
        phone: '+61 4 2345 6789',
        commission: 0
    },
    {
        id: 3,
        status: 'CONFIRMED',
        dateReferred: '2023-09-22T11:45:00',
        companyName: 'Sunshine Retail Group',
        contactName: 'Emma Wilson',
        email: 'emma@sunshineretail.com',
        phone: '+61 4 3456 7890',
        commission: 250.00
    },
    {
        id: 4,
        status: 'PENDING',
        dateReferred: '2023-11-10T16:20:00',
        companyName: 'Oceanview Properties',
        contactName: 'James Taylor',
        email: 'jtaylor@oceanview.com',
        phone: '+61 4 4567 8901',
        commission: 0
    },
    {
        id: 5,
        status: 'CONFIRMED',
        dateReferred: '2023-08-30T10:00:00',
        companyName: 'Innovative Healthcare',
        contactName: 'Olivia Brown',
        email: 'olivia@innovativehealth.com',
        phone: '+61 4 5678 9012',
        commission: 250.00
    },
    {
        id: 6,
        status: 'CONFIRMED',
        dateReferred: '2023-07-15T13:30:00',
        companyName: 'Green Energy Solutions',
        contactName: 'Daniel Smith',
        email: 'daniel@greenenergy.com',
        phone: '+61 4 6789 0123',
        commission: 250.00
    },
];

// Marketing materials data
const MARKETING_MATERIALS = [
    {
        id: 1,
        title: 'MPLOI Short Description',
        description: 'A concise overview of MPLOI Accounts Receivable for quick pitches.',
        content: 'MPLOI Accounts Receivable: AI-powered collection calls that recover outstanding invoices automatically, saving you time and improving cash flow.',
        type: 'text'
    },
    {
        id: 2,
        title: 'MPLOI Long Description',
        description: 'Detailed explanation of MPLOI AR features and benefits.',
        content: 'MPLOI Accounts Receivable revolutionizes how businesses handle collections. Our AI-powered system automatically calls customers with overdue invoices, using natural conversation to arrange payments. With seamless integration to accounting platforms, customizable voice and personality settings, and detailed analytics, MPLOI AR reduces your admin workload while improving cash flow. The system handles follow-ups, payment arrangements, and even difficult conversations - all without requiring additional staff.',
        type: 'text'
    },
    {
        id: 3,
        title: 'Product Demo Video',
        description: 'See MPLOI AR in action with this comprehensive demo.',
        url: 'https://mploi.com/demo-video',
        type: 'video'
    },
    {
        id: 4,
        title: 'Feature Highlights',
        description: 'Quick overview of key MPLOI AR features.',
        url: 'https://mploi.com/features',
        type: 'link'
    },
    {
        id: 5,
        title: 'Case Studies',
        description: 'Real-world success stories from MPLOI AR users.',
        url: 'https://mploi.com/case-studies',
        type: 'link'
    },
    {
        id: 6,
        title: 'Promotional Images',
        description: 'High-quality images for marketing materials.',
        url: 'https://mploi.com/promo-images',
        type: 'download'
    },
];

const ARAffiliates = (props) => {
    const dispatch = useDispatch();
    const state = useSelector((state: State) => state);
    const [userData, setUserData] = useState(null);
    const { setUnreadNotificationCount } = bindActionCreators(actionCreators, dispatch);
    const [showGuestAccessPrompt, setShowGuestAccessPrompt] = useState(false);
    const [unreadMsgCount, setUnreadMsgCount] = useState(0);
    const user = cookieService?.getCookie(STRING_CONSTANTS?.cookieStrings?.user);
    const isUserLoggedIn = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.token);
    
    // Affiliate data
    const [affiliateCode, setAffiliateCode] = useState('MPLOI7X29');
    const [affiliateUrl, setAffiliateUrl] = useState(`https://mploi.com/${affiliateCode}`);
    const [referrals, setReferrals] = useState(SAMPLE_REFERRALS);
    const [copiedCode, setCopiedCode] = useState(false);
    const [copiedUrl, setCopiedUrl] = useState(false);
    
    // Stats
    const [stats, setStats] = useState({
        totalReferrals: SAMPLE_REFERRALS.length,
        confirmedReferrals: SAMPLE_REFERRALS.filter(ref => ref.status === 'CONFIRMED').length,
        totalCommission: SAMPLE_REFERRALS.reduce((sum, ref) => sum + ref.commission, 0)
    });

    useEffect(() => {
        if (isUserLoggedIn) {
            userService.getUserData()
                .then(res => {
                    cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, res);
                    setUnreadNotificationCount(res?.user?.unreadNotificationCount);
                    setUserData(res);
                    
                    // In a real implementation, you would fetch the affiliate code from the user data
                    // setAffiliateCode(res?.user?.affiliateCode || 'MPLOI7X29');
                    // setAffiliateUrl(`https://mploi.com/${res?.user?.affiliateCode || 'MPLOI7X29'}`);
                });
        }
    }, []);

    const handleCopyCode = () => {
        setCopiedCode(true);
        setTimeout(() => setCopiedCode(false), 2000);
        SuccessToast("Success", "Affiliate code copied to clipboard!");
    };

    const handleCopyUrl = () => {
        setCopiedUrl(true);
        setTimeout(() => setCopiedUrl(false), 2000);
        SuccessToast("Success", "Affiliate URL copied to clipboard!");
    };

    const handleContactCustomer = (referral) => {
        // In a real implementation, this would open a contact modal or redirect to a contact page
        SuccessToast("Contact Initiated", `Contacting ${referral.contactName} from ${referral.companyName}`);
    };

    return (
        <section className="main-dashboard-wrap" style={styles.container}>
            <Helmet>
                <title>MPLOI: Affiliate Program</title>
            </Helmet>
            <div id="wrapper" style={{ height: "100vh" }}>
                {/* Sidebar */}
                <ul className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ${state.common.showSidebarOnMobileView ? "active" : ""}`} id="accordionSidebar">
                    <div>
                        <Link style={{ pointerEvents: "none" }} className="sidebar-brand d-flex align-items-center justify-content-center" to="#">
                            <img src={UtilityFunctions.getImageURL() + "db-logo.svg"} alt="Logo" />
                        </Link>
                        {!user?.user?.isAccountsSignup && <li className="nav-item">
                            <Link className="nav-link" to="/dashboard/home">
                                <img src={UtilityFunctions.getImageURL() + "home-icon-blank-white.svg"} alt="" />
                                <img src={UtilityFunctions.getImageURL() + "home-icon-fill-green.svg"} alt="" />
                            </Link>
                        </li>}
                        {user?.user?.isAccountsSignup && <li className="nav-item">
                            <Link className="nav-link" to="/ar">
                                <img src={UtilityFunctions.getImageURL() + "home-icon-blank-white.svg"} alt="" />
                                <img src={UtilityFunctions.getImageURL() + "home-icon-fill-green.svg"} alt="" />
                            </Link>
                        </li>}

                        {!user?.user?.isAccountsSignup && <li className="nav-item notification-nav">
                            <Link className="nav-link" to="/dashboard/chat/">
                                <div className="svg-box">
                                    <img src={UtilityFunctions.getImageURL() + "chat-icon.svg"} alt="" />
                                    <img src={UtilityFunctions.getImageURL() + "chat-icon-fill-green.svg"} alt="" />
                                    {unreadMsgCount ? <span>{unreadMsgCount > 9 ? "9+" : unreadMsgCount}</span> : ""}
                                </div>
                            </Link>
                        </li>}
                    </div>

                    <div>
                        <li className="nav-item">
                            <UpgradePlanCheck
                                isUserLoggedIn={isUserLoggedIn}
                                setShowGuestAccessPrompt={setShowGuestAccessPrompt}
                                userData={userData}
                            />
                        </li>
                    </div>

                    <div>
                        <li className="nav-item notification-nav">
                            <span className="nav-link"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    isUserLoggedIn ?
                                        props.history.push(STRING_CONSTANTS.navigationString.dashboard.notifications) :
                                        setShowGuestAccessPrompt(true)
                                }}
                            >
                                <div className="svg-box">
                                    <img src={UtilityFunctions.getImageURL() + "bell-icon-blank-white.svg"} alt="" />
                                    <img src={UtilityFunctions.getImageURL() + "bell-icon-fill-green.svg"} alt="" />
                                    {state?.unreadNotificationCount?.unreadNotificationCount ? 
                                        <span>
                                            {state?.unreadNotificationCount?.unreadNotificationCount > 9 ? 
                                                "9+" : state?.unreadNotificationCount?.unreadNotificationCount}
                                        </span> : ""}
                                </div>
                            </span>
                        </li>
                        <li className="nav-item">
                            <span className="nav-link"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    isUserLoggedIn ?
                                        props.history.push(STRING_CONSTANTS.navigationString.dashboard.settings) :
                                        setShowGuestAccessPrompt(true)
                                }}
                            >
                                <img src={UtilityFunctions.getImageURL() + "settings-icon-blank-white.svg"} alt="" />
                                <img src={UtilityFunctions.getImageURL() + "settings-icon-fill-green.svg"} alt="" />
                            </span>
                        </li>
                        <li className='profile-pic d-grid justify-content-center gap-2 text-white'>
                            <img src={
                                userData?.user?.type === STRING_CONSTANTS.userType.employer ? 
                                    (userData?.logo || UtilityFunctions.getImageURL() + "profile-pic.png") : 
                                    (userData?.profilePhoto || UtilityFunctions.getImageURL() + "default-employee-profile-pic-round.svg")
                            } alt="Profile" />
                            {!isUserLoggedIn && 
                                <span style={{ cursor: "pointer" }}
                                    onClick={() => props.history.push({
                                        pathname: STRING_CONSTANTS.navigationString.signup,
                                        state: { isGuest: true }
                                    })}
                                >
                                    Signup
                                </span>
                            }
                        </li>
                    </div>
                </ul>

                {/* Content Wrapper */}
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        {/* Top Bar */}
                        <div style={styles.topBar}>
                            <h1 style={styles.topBarTitle}>Affiliate Program</h1>
                            <div>
                                <button 
                                    style={{...styles.button, ...styles.primaryButton}}
                                    onClick={() => props.history.push('/ar')}
                                >
                                    Back to Dashboard
                                </button>
                            </div>
                        </div>

                        {/* Main Content */}
                        <div className="container-fluid">
                            {/* Affiliate Code Section */}
                            <div style={styles.affiliateCodeBox}>
                                <h3 style={styles.whiteText}>Your Affiliate Code</h3>
                                <div style={styles.affiliateCode}>{affiliateCode}</div>
                                <CopyToClipboard text={affiliateCode} onCopy={handleCopyCode}>
                                    <button style={styles.copyButton}>
                                        {copiedCode ? 'Copied!' : 'Copy Code'}
                                    </button>
                                </CopyToClipboard>
                                
                                <div style={styles.urlBox}>
                                    <span style={styles.urlText}>{affiliateUrl}</span>
                                    <CopyToClipboard text={affiliateUrl} onCopy={handleCopyUrl}>
                                        <button style={styles.copyButton}>
                                            {copiedUrl ? 'Copied!' : 'Copy URL'}
                                        </button>
                                    </CopyToClipboard>
                                </div>
                            </div>

                            {/* Stats Cards */}
                            <div className="row mb-4">
                                <div className="col-xl-4 col-md-4">
                                    <div style={styles.blueBox}>
                                        <div style={styles.statsCard}>
                                            <div style={styles.statsNumber}>{stats.totalReferrals}</div>
                                            <div style={styles.statsLabel}>Total Referrals</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-md-4">
                                    <div style={styles.blueBox}>
                                        <div style={styles.statsCard}>
                                            <div style={styles.statsNumber}>{stats.confirmedReferrals}</div>
                                            <div style={styles.statsLabel}>Confirmed Referrals</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-md-4">
                                    <div style={styles.blueBox}>
                                        <div style={styles.statsCard}>
                                            <div style={styles.statsNumber}>${stats.totalCommission.toFixed(2)}</div>
                                            <div style={styles.statsLabel}>Total Commission</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Marketing Materials Section */}
                            <div style={styles.blueBox}>
                                <h3 style={{...styles.whiteText, marginBottom: '20px'}}>
                                    <img src={UtilityFunctions.getImageURL() + "marketing-icon.svg"} 
                                         style={{marginRight: '10px', width: '24px', height: '24px'}} 
                                         alt="" />
                                    Marketing Materials
                                </h3>
                                
                                {MARKETING_MATERIALS.map((material) => (
                                    <div key={material.id} style={styles.marketingItem}>
                                        <div>
                                            <h4 style={styles.marketingItemTitle}>{material.title}</h4>
                                            <p style={styles.marketingItemDescription}>{material.description}</p>
                                            
                                            {material.type === 'text' && (
                                                <div style={{
                                                    backgroundColor: 'rgba(255, 255, 255, 0.05)',
                                                    padding: '15px',
                                                    borderRadius: '6px',
                                                    marginTop: '10px',
                                                    color: '#ffffff'
                                                }}>
                                                    {material.content}
                                                </div>
                                            )}
                                        </div>
                                        
                                        {material.type !== 'text' && (
                                            <CopyToClipboard text={material.url}>
                                                <button style={styles.copyButton}>
                                                    {material.type === 'download' ? 'Download' : 
                                                     material.type === 'video' ? 'Watch Video' : 'Visit Link'}
                                                </button>
                                            </CopyToClipboard>
                                        )}
                                    </div>
                                ))}
                            </div>

                            {/* Referrals Table */}
                            <div style={styles.blueBox}>
                                <h3 style={{...styles.whiteText, marginBottom: '20px'}}>
                                    <img src={UtilityFunctions.getImageURL() + "users-icon.svg"} 
                                         style={{marginRight: '10px', width: '24px', height: '24px'}} 
                                         alt="" />
                                    Your Referrals
                                </h3>
                                <div className="table-responsive">
                                    <table style={styles.table}>
                                        <thead>
                                            <tr>
                                                <th style={styles.tableHeader}>Status</th>
                                                <th style={styles.tableHeader}>Date Referred</th>
                                                <th style={styles.tableHeader}>Company Name</th>
                                                <th style={styles.tableHeader}>Contact</th>
                                                <th style={styles.tableHeader}>Commission</th>
                                                <th style={styles.tableHeader}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {referrals.map(referral => (
                                                <tr key={referral.id}>
                                                    <td style={styles.tableCell}>
                                                        <span style={{
                                                            ...styles.badge,
                                                            ...(referral.status === 'PENDING' ? styles.pendingBadge : styles.confirmedBadge)
                                                        }}>
                                                            {referral.status}
                                                        </span>
                                                    </td>
                                                    <td style={styles.tableCell}>
                                                        {moment(referral.dateReferred).format('DD/MM/YYYY')}
                                                    </td>
                                                    <td style={styles.tableCell}>{referral.companyName}</td>
                                                    <td style={styles.tableCell}>
                                                        <div>{referral.contactName}</div>
                                                        <div style={styles.mutedText}>{referral.email}</div>
                                                    </td>
                                                    <td style={styles.tableCell}>
                                                        {referral.status === 'CONFIRMED' ? 
                                                            `$${referral.commission.toFixed(2)}` : 
                                                            '—'}
                                                    </td>
                                                    <td style={styles.tableCell}>
                                                        <button 
                                                            style={styles.contactButton}
                                                            onClick={() => handleContactCustomer(referral)}
                                                        >
                                                            Contact
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            {/* Affiliate Program Info */}
                            <div style={styles.infoBox}>
                                <h3 style={styles.infoTitle}>
                                    <img src={UtilityFunctions.getImageURL() + "info-icon.svg"} 
                                         style={{width: '24px', height: '24px'}} 
                                         alt="" />
                                    How Our Affiliate Program Works
                                </h3>
                                
                                <div style={styles.infoPoint}>
                                    <span style={styles.infoIcon}>✓</span>
                                    <p style={styles.infoText}>
                                        <strong>Commission Structure:</strong> Earn $250 for each confirmed referral that subscribes to MPLOI Accounts Receivable.
                                    </p>
                                </div>
                                
                                <div style={styles.infoPoint}>
                                    <span style={styles.infoIcon}>✓</span>
                                    <p style={styles.infoText}>
                                        <strong>30-Day Cookie:</strong> Your referrals are tracked for 30 days from their first visit using your affiliate link.
                                    </p>
                                </div>
                                
                                <div style={styles.infoPoint}>
                                    <span style={styles.infoIcon}>✓</span>
                                    <p style={styles.infoText}>
                                        <strong>Payment Terms:</strong> Commissions are paid monthly for all confirmed referrals from the previous month.
                                    </p>
                                </div>
                                
                                <div style={styles.infoPoint}>
                                    <span style={styles.infoIcon}>✓</span>
                                    <p style={styles.infoText}>
                                        <strong>Confirmation Process:</strong> Referrals are confirmed after they complete their first month's subscription.
                                    </p>
                                </div>
                                
                                <div style={styles.infoPoint}>
                                    <span style={styles.infoIcon}>✓</span>
                                    <p style={styles.infoText}>
                                        <strong>Marketing Support:</strong> Use our provided marketing materials to promote MPLOI effectively.
                                    </p>
                                </div>
                                
                                <div style={styles.infoPoint}>
                                    <span style={styles.infoIcon}>✓</span>
                                    <p style={styles.infoText}>
                                        <strong>Tracking:</strong> All referrals are automatically tracked through your unique affiliate code.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ARAffiliates;