import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Section1AR from "../../components/LandingPage/Section1AR/Section1AR";
import Section3 from "../../components/LandingPage/Section3/Section3";
import "./Employers.css";
import EmployerForm from "./EmployerForm";
import Slider from "react-slick";
import { Helmet } from 'react-helmet';

export default function AccountsReceivable() {
  const originalAccrodianData = [
    {
      heading: (
        <>
          <div className=" mr-2">Your AI - Powered Invoice Follow-ups</div>
          <img
            src="/assets/images/employeesLandingPage/exploreFirst.svg"
            alt=""
            className="iconImage"
          />
        </>
      ),
      description: (
        <div>
          Asking your customers to pay <strong>outstanding invoices</strong> can be one of the most uncomfortable tasks a business owner has to face……not to mention all the time it takes away from you actually running your business or quality time away from your family.
          <br /><br />
          Imagine unlocking “Cash Flow” in your business from outstanding invoices with AI Accounts Receivable….
        </div>
      ),
    },
    {
      heading: (
        <>
          <div className=" mr-2">Your Invoice Software Integrations</div>
          <img
            src="/assets/images/employersLandingPage/briefcase.svg"
            alt=""
            className="iconImage"
          />
        </>
      ),

      description: (
        <div>
          MPLOI’s AI Accounts Receivable integrates with all your favourite accounting software. If there is software that you are using and you can’t see the API plugin, don’t stress, you can let us know the software you are using and we can build the connection or upload an excel spreadsheet of your outstanding debtors.
        </div>
      ),
    },
    {
      heading: (
        <>
          <div className=" mr-2">Customise Your Own AI Team Member</div>
          <img
            src="/assets/images/employeesLandingPage/exploreFourth.svg"
            alt=""
            className="iconImage"
          />
        </>
      ),

      description: (
        <div>
          Choose from multiple voices and tonalities for your AI Team Member giving your customers the best possible experience throughout the entire interaction. We do want unpaid invoices coming in whilst still keeping your customers loving your company.
        </div>
      ),
    },
    {
      heading: (
        <>
          <div className=" mr-2">Your Personalised AI-powered accounts receivable package….</div>
          <img
            src="/assets/images/employersLandingPage/hammer.svg"
            alt=""
            className="iconImage"
          />
        </>
      ),

      description: (
        <div>
          <ul style={{ paddingLeft: "30px" }}>
            <li>Your own personalised mobile number designated to your business</li>
            <li>Every call is transcribed, logged and emailed directly to you so you can see the outcome.</li>
            <li>Your AI powered Accounts Receivable will be able to leave voice mail messages requesting a call back to your own unique mobile number.</li>
            <li>Your AI powered Accounts Receivable will handle and interact with returned calls.</li>
            <li>Your own MPLOI AI Accounts Receivable will remember and follow up previous conversations with your debtors.</li>
          </ul>
        </div>
      ),
    },
  ];

  const [expanded, setExpanded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [accrodianData, setAccrodianData] = useState(() => {
    return window.innerWidth < 768
      ? originalAccrodianData.slice(0, 3)
      : originalAccrodianData;
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleResetData = (slice = false) => {
    if (slice) {
      setAccrodianData(originalAccrodianData.slice(0, 3));
      setExpanded(false);
    } else {
      setAccrodianData(originalAccrodianData);
      setExpanded(true);
    }
  };

  const sliderData = [
    {
      value: "87%",
      description:
        "Millennials say that professional development/ career growth are important to them.",
      color: "#9256E8",
      bgColor: "#9256E817",
    },
    {
      value: "74%",
      description:
        "Workers say that a lack of employee development opportunities prevents them from reaching their full potential.",
      color: "#F495D3",
      bgColor: "#F495D317",
    },
    {
      value: "58%",
      description:
        "Employees say professional development contributes to their job satisfaction.",
      color: "#38BBF4",
      bgColor: "#38BBF417",
    },
    {
      value: "68%",
      description:
        "Employees say training and development are the most essential company policy",
      color: "#FFE35A",
      bgColor: "#2A1D29",
    },
    {
      value: "74%",
      description:
        "Workers are willing to learn new skills or retrain to stay employable say that a lack of employee development opportunities prevents them from reaching their full potential.",
      color: "#06EFC5",
      bgColor: "#06EFC517",
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5.1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 2400,
        settings: {
          slidesToShow: 4.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 2200,
        settings: {
          slidesToShow: 3.8,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 3.1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 825,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  //   Revolutionize Your Cash Flow:
  // AI-Powered Accounts Receivable
  // Made Simple with MPLOI

  // Experience the Revolution in Invoice Collection Firsthand
  return (
    <div className="employersPageWrap">
      <Helmet>
        <title>MPLOI: Boost Your Cash Flow with AI-Powered Invoice Collection</title>
      </Helmet>
      <Header />
      <Section1AR
        heading={
          <>
            <div className=" fw-bold heading">
              Boost Your Cash Flow with<br />
              <span className=" px-2" style={{ background: "#9256E8" }}>
                AI-Powered
              </span>{" "}<br />
              Invoice Collection<br />
            </div>
          </>
        }
        subHeading="MPLOI's advanced AI takes the hassle out of chasing unpaid invoices, so you can focus on growing your business. Our intelligent AI system calls all your customers with outstanding invoices, maintaining a professional and friendly tone, reminding them of outstanding invoices and facilitating immediate payments."
        buttons={
          <>
            <a href="/signup?type=accounts" className="textBlack">
              <button className=" border-0 px-4 py-2 primaryButton">
                Unlock Cash Now
                <img
                  src="assets/images/employersLandingPage/teamMembers.svg"
                  alt=""
                />
              </button>
            </a>
            <a href="#employers-feature">
              <button className=" border-0 px-4 py-2 secondaryButton">
                Explore Accounts Receivable Features
                <img
                  src="assets/images/employeesLandingPage/exploreIcon.svg"
                  alt=""
                />
              </button>
            </a>
          </>
        }
        image={
          <>
            <EmployerForm />
          </>
        }
        mobileImage={
          <>
            <EmployerForm />
          </>
        }
      />
      <div className="section2">
        <div className="heading">
        MPLOI: AI-powered accounts receivable,<br />designed by business owners<br />for smarter cash flow management.
        </div>
        <div className="sec-2-btn">
          <a href="/signup?type=accounts" className="textBlack">
            <button className=" border-0 px-5 py-2 primaryButton">
              Join MPLOI Today!
              <img
                src="assets/images/employeesLandingPage/testYourself.svg"
                alt=""
              />
            </button>
          </a>
        </div>
      </div>
      <div id="employers-feature">
        <Section3
          accrodianData={accrodianData}
          tagName="For Business Owners"
          description="Explore MPLOI’s Accounts Receivable Tool for Business Owners"
          image={
            <img
              width="650"
              src="/assets/images/lp_images/accounts_receivable/ar1.png"
              className="img-fluid"
               alt=""
            />
          }
          mobilebutton={
            isMobile ? (
              expanded ? (
                <button
                  className=" border-0 px-5 py-2 primaryButton textBlack"
                  onClick={() => handleResetData(true)}
                >
                  Expand Less
                  <img
                    src="assets/images/downArrowBlack.svg"
                    alt=""
                    style={{ transform: "rotate(180deg)" }}
                  />
                </button>
              ) : (
                <button
                  className=" border-0 px-5 py-2 primaryButton textBlack"
                  onClick={() => handleResetData()}
                >
                  Expand All
                  <img src="assets/images/downArrowBlack.svg" alt="" />
                </button>
              )
            ) : (
              ""
            )
          }
        />
      </div>
      <Footer />
    </div>
  );
}
