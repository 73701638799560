import React, { useState, Fragment } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { UtilityFunctions } from "../../utils/UtilityFunctions";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";
import { Helmet } from 'react-helmet';
import { userService, cookieService } from "../../_services";

// Define props type including router props
interface ARAffiliateSignupProps extends RouteComponentProps<any> {}

const ARAffiliateSignup: React.FC<ARAffiliateSignupProps> = (props) => {
  // --- State Management ---
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [password, setPassword] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [isGstRegistered, setIsGstRegistered] = useState<boolean | null>(null);
  const [paypalId, setPaypalId] = useState('');
  const [industry, setIndustry] = useState('');
  const [promotionStrategy, setPromotionStrategy] = useState('');
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [pwdType, setPwdType] = useState("password");
  const [btnClick, setBtnClick] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState<string | null>(null);
  const [fullName, setFullName] = useState('');


  // --- Validation ---
  const hasNumber = /\d/;
  const isEmailValid = UtilityFunctions.validateEmail(email);
  const isPasswordValid = password.length >= 8 && hasNumber.test(password) && !UtilityFunctions.checkSpaceInPassword(password);
  const isMobileValid = !UtilityFunctions.isEmptyOrNullOrUndefined(mobileNumber);
  const isCompanyNameValid = !UtilityFunctions.isEmptyOrNullOrUndefined(companyName);
  const isIndustryValid = !UtilityFunctions.isEmptyOrNullOrUndefined(industry);
  const isPaymentIdProvided = !UtilityFunctions.isEmptyOrNullOrUndefined(paypalId);
  const isPromotionStrategyValid = !UtilityFunctions.isEmptyOrNullOrUndefined(promotionStrategy);
  const isFullNameValid = !UtilityFunctions.isEmptyOrNullOrUndefined(fullName);
  const isGstStatusSelected = isGstRegistered !== null;

  // Overall form validity check
  const isFormValid =
    isEmailValid &&
    isPasswordValid &&
    isMobileValid &&
    isCompanyNameValid &&
    isIndustryValid &&
    isPaymentIdProvided &&
    isFullNameValid &&
    isPromotionStrategyValid &&
    isGstStatusSelected &&
    agreedToTerms;

  // --- Handlers ---
  const togglePassword = () => {
    setPwdType(pwdType === "password" ? "text" : "password");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setBtnClick(true);
    setApiError(null);
  
    if (!isFormValid) {
      console.log("Affiliate form validation failed.");
      if (!agreedToTerms) setApiError("You must agree to the Affiliate Agreement.");
      else if (!isPaymentIdProvided) setApiError("Please provide your PayPal Email.");
      else if (!isPromotionStrategyValid) setApiError("Please describe how you plan to promote.");
      else if (!isGstStatusSelected) setApiError("Please indicate your GST registration status.");
      else setApiError("Please fill in all required fields correctly.");
      return;
    }
  
    setIsLoading(true);
    try {
      // Step 1: Perform the affiliate signup
      const affiliateData = {
        email,
        fullName,
        mobileNumber,
        password,
        companyName,
        paypalId,
        industry,
        promotionStrategy,
        isGstRegistered,
        agreedToTerms,
        type: 'AFFILIATE',
        is_ar_affiliate_signup: true
      };
      
      console.log("Attempting AR Affiliate Signup with:", affiliateData);
      const signupResponse = await userService.arAffiliateSignup(affiliateData);
      console.log("AR Affiliate Signup Response:", signupResponse.data);
      
      // Step 2: Now perform a standard login to get properly formatted tokens
      const loginData = {
        email: email,
        password: password
      };
      
      console.log("Performing standard login with:", loginData);
      const loginResponse = await userService.login(loginData);
      console.log("Login Response:", loginResponse.data);
      
      // Step 3: Save the tokens from the login response
      if (loginResponse.data && loginResponse.data.user && loginResponse.data.user.token) {
        // Save in localStorage exactly as your app expects
        localStorage.setItem('access_token', loginResponse.data.user.token.access);
        localStorage.setItem('refresh_token', loginResponse.data.user.token.refresh);
        localStorage.setItem('user_data', JSON.stringify(loginResponse.data.user));
        localStorage.setItem('isGuest', 'false');
        
        // Also save using cookieService for secure storage
        cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.token, loginResponse.data.user.token.access);
        cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, loginResponse.data.user);
        cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.isGuest, false);
        
        // Log what we've stored
        console.log("Auth data saved successfully:", {
          access_token: "Saved to localStorage",
          refresh_token: "Saved to localStorage",
          user_data: "Saved to localStorage",
          secure_storage: "Saved via cookieService"
        });
        
        // Step 4: Redirect to the dashboard with a full page reload
        // This ensures all auth data is properly recognized
        window.location.href = '/ar/affiliate/dashboard';
      } else {
        throw new Error("Login response did not contain required authentication data");
      }
    } catch (error) {
      console.error("AR Affiliate Signup/Login Failed:", error);
      
      // Handle specific error cases
      if (error.response?.status === 400 && error.response?.data?.email) {
        setApiError(`Email error: ${error.response.data.email[0]}`);
      } else if (error.response?.status === 401) {
        setApiError("Authentication failed. Please try again with different credentials.");
      } else {
        setApiError(
          error.response?.data?.error || 
          error.response?.data?.message || 
          error.message || 
          "An unexpected error occurred during registration."
        );
      }
    } finally {
      setIsLoading(false);
    }
  };
  // --- Navigation Data ---
  const affiliateAgreement = { pathname: `/affiliate-agreement`, state: {} };
  const loginPage = STRING_CONSTANTS.navigationString.login;
  const onBoarding = { pathname: "/", state: { activeIndex: 2 } };

  // --- Render ---
  return (
    <Fragment>
      <Helmet>
        <title>MPLOI: Affiliate Signup</title>
      </Helmet>
      <section className="main-signup-wrap">
        <form noValidate onSubmit={handleSubmit} style={{ textAlign: 'left', width: '100%' }}>
          <div className="row">
            {/* Left Column */}
            <div className="col-md-3 col-lg-4 col-xl-4 col-12">
              <div className="left-wrap">
                <div className="logo-wrap">
                  <img
                    alt="MPLOI Logo"
                    src={UtilityFunctions.getImageURL() + "logo_White.svg"}
                    onClick={() => !isLoading && props.history.push(onBoarding)}
                    style={{ cursor: 'pointer' }}
                  />
                </div>
              </div>
            </div>

            {/* Middle Column (Form Content) */}
            <div className="col-md-6 col-lg-4 col-xl-4 col-12">
              <div className="middle-wrap">
                {/* Top Section */}
                <div style={{ marginBottom: '20px', textAlign: 'left' }}>
                  <span>
                    <i
                      className="fa fa-angle-left text-white d-flex justify-content-between"
                      aria-hidden="true"
                      style={{ cursor: 'pointer' }}
                      onClick={() => !isLoading && props.history.push('/')}
                    ></i>
                  </span>
                  <div className="step-content" style={{ textAlign: 'left' }}>
                    <button type='button' disabled>Affiliate Signup</button>
                    <p>Join our partner program</p>
                  </div>
                </div>

                {/* Form Section */}
                <div className="form-wrap" style={{marginTop: "20px"}}>
                  <div className='v-form-width' style={{ textAlign: 'left', width: '100%' }}>

                    <div style={{ marginBottom: '1rem', textAlign: 'left' }}>
                      <label htmlFor="affFullName" style={{ color: 'white', fontSize: '14px', marginBottom: '5px', textAlign: 'left', display: 'block' }}>Full Name</label>
                      <input 
                        type="text" 
                        style={{
                          height: '40px',
                          padding: '0.5rem 0.75rem',
                          color: '#fff',
                          backgroundColor: '#1e1e30',
                          border: '1px solid #2d2d3d',
                          borderRadius: '4px',
                          transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
                          textAlign: 'left',
                          width: '100%'
                        }}
                        className={`${btnClick && !isFullNameValid ? 'error' : ""}`} 
                        id="affFullName" 
                        value={fullName} 
                        onChange={(e) => { setFullName(e.target.value); setBtnClick(false); setApiError(null); }} 
                        disabled={isLoading} 
                      />
                      {btnClick && !isFullNameValid && 
                        <div style={{ color: '#dc3545', fontSize: '12px', marginTop: '0.25rem', textAlign: 'left' }}>Please enter your full name</div>
                      }
                    </div>

                    {/* Email Input */}
                    <div style={{ marginBottom: '1rem', textAlign: 'left' }}>
                      <label htmlFor="affEmail" style={{ color: 'white', fontSize: '14px', marginBottom: '5px', textAlign: 'left', display: 'block' }}>Email Address</label>
                      <input 
                        type="email" 
                        style={{
                          height: '40px',
                          padding: '0.5rem 0.75rem',
                          color: '#fff',
                          backgroundColor: '#1e1e30',
                          border: '1px solid #2d2d3d',
                          borderRadius: '4px',
                          transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
                          textAlign: 'left',
                          width: '100%'
                        }}
                        className={`${btnClick && !isEmailValid ? 'error' : ""}`} 
                        id="affEmail" 
                        value={email} 
                        onChange={(e) => { setEmail(e.target.value); setBtnClick(false); setApiError(null); }} 
                        disabled={isLoading} 
                      />
                      {btnClick && !isEmailValid && 
                        <div style={{ color: '#dc3545', fontSize: '12px', marginTop: '0.25rem', textAlign: 'left' }}>Please enter a valid email address</div>
                      }
                    </div>

                    {/* Mobile Number Input */}
                    <div style={{ marginBottom: '1rem', textAlign: 'left' }}>
                      <label htmlFor="affMobile" style={{ color: 'white', fontSize: '14px', marginBottom: '5px', textAlign: 'left', display: 'block' }}>Mobile Number</label>
                      <input 
                        type="tel" 
                        style={{
                          height: '40px',
                          padding: '0.5rem 0.75rem',
                          color: '#fff',
                          backgroundColor: '#1e1e30',
                          border: '1px solid #2d2d3d',
                          borderRadius: '4px',
                          transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
                          textAlign: 'left',
                          width: '100%'
                        }}
                        className={`${btnClick && !isMobileValid ? 'error' : ""}`} 
                        id="affMobile" 
                        value={mobileNumber} 
                        onChange={(e) => { setMobileNumber(e.target.value); setBtnClick(false); setApiError(null); }} 
                        disabled={isLoading} 
                      />
                      {btnClick && !isMobileValid && 
                        <div style={{ color: '#dc3545', fontSize: '12px', marginTop: '0.25rem', textAlign: 'left' }}>Please enter your mobile number</div>
                      }
                    </div>

                    {/* Password Input with eye toggle */}
                    <div style={{ marginBottom: '1rem', textAlign: 'left' }}>
                      <label htmlFor="affPassword" style={{ color: 'white', fontSize: '14px', marginBottom: '5px', textAlign: 'left', display: 'block' }}>Password</label>
                      <div style={{ position: 'relative' }}>
                        <input 
                          type={pwdType} 
                          style={{
                            height: '40px',
                            padding: '0.5rem 0.75rem',
                            color: '#fff',
                            backgroundColor: '#1e1e30',
                            border: '1px solid #2d2d3d',
                            borderRadius: '4px',
                            transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
                            textAlign: 'left',
                            width: '100%'
                          }}
                          className={`${btnClick && !isPasswordValid ? "error" : ""}`} 
                          id="affPassword" 
                          value={password} 
                          onChange={(e) => { setPassword(e.target.value); setBtnClick(false); setApiError(null); }} 
                          disabled={isLoading} 
                        />
                        <div 
                          onClick={togglePassword} 
                          style={{ 
                            position: 'absolute', 
                            right: '10px', 
                            top: '50%', 
                            transform: 'translateY(-50%)', 
                            cursor: 'pointer',
                            zIndex: 2
                          }}
                        >
                          <i className={`fa ${pwdType === "password" ? "fa-eye-slash" : "fa-eye"}`} style={{color: '#8a8a8a'}}></i>
                        </div>
                      </div>
                    </div>
                    
                    <ul className="list-unstyled circle mb-3" style={{ textAlign: 'left' }}>
                      <li className={` ${password.length <= 0 ? "" : password.length >= 8 && !UtilityFunctions.checkSpaceInPassword(password) ? "text-ok" : "text-red"}`}>
                        <span>{STRING_CONSTANTS.validationString.min_chars}</span>
                      </li>
                      <li className={`${password.length <= 0 ? "" : hasNumber.test(password) ? "text-ok" : "text-red"}`}>
                        <span>{STRING_CONSTANTS.validationString.number_req}</span>
                      </li>
                    </ul>

                    {/* Company Name Input */}
                    <div style={{ marginBottom: '1rem', textAlign: 'left' }}>
                      <label htmlFor="affCompanyName" style={{ color: 'white', fontSize: '14px', marginBottom: '5px', textAlign: 'left', display: 'block' }}>Company Name</label>
                      <input 
                        type="text" 
                        style={{
                          height: '40px',
                          padding: '0.5rem 0.75rem',
                          color: '#fff',
                          backgroundColor: '#1e1e30',
                          border: '1px solid #2d2d3d',
                          borderRadius: '4px',
                          transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
                          textAlign: 'left',
                          width: '100%'
                        }}
                        className={`${btnClick && !isCompanyNameValid ? 'error' : ""}`} 
                        id="affCompanyName" 
                        value={companyName} 
                        onChange={(e) => { setCompanyName(e.target.value); setBtnClick(false); setApiError(null); }} 
                        disabled={isLoading} 
                      />
                      {btnClick && !isCompanyNameValid && 
                        <div style={{ color: '#dc3545', fontSize: '12px', marginTop: '0.25rem', textAlign: 'left' }}>Please enter your company name</div>
                      }
                    </div>

                    {/* GST Registration Status */}
                    <div style={{ marginBottom: '1rem', textAlign: 'left' }}>
                      <label style={{ color: 'white', fontSize: '14px', marginBottom: '5px', textAlign: 'left', display: 'block' }}>
                        Is your business registered for GST?*
                      </label>
                      <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.5rem', color: 'white', textAlign: 'left' }}>
                        <div style={{ marginRight: '2rem', display: 'flex', alignItems: 'center' }}>
                          <input
                            type="radio"
                            id="gstYes"
                            name="gstRegistration"
                            style={{ marginRight: '0.5rem', cursor: 'pointer', accentColor: '#06EFC5' }}
                            checked={isGstRegistered === true}
                            onChange={() => { setIsGstRegistered(true); setBtnClick(false); setApiError(null); }}
                            disabled={isLoading}
                          />
                          <label htmlFor="gstYes" style={{ cursor: 'pointer', fontSize: '14px', color: 'white' }}>Yes</label>
                        </div>
                        <div style={{ marginRight: '2rem', display: 'flex', alignItems: 'center' }}>
                          <input
                            type="radio"
                            id="gstNo"
                            name="gstRegistration"
                            style={{ marginRight: '0.5rem', cursor: 'pointer', accentColor: '#06EFC5' }}
                            checked={isGstRegistered === false}
                            onChange={() => { setIsGstRegistered(false); setBtnClick(false); setApiError(null); }}
                            disabled={isLoading}
                          />
                          <label htmlFor="gstNo" style={{ cursor: 'pointer', fontSize: '14px', color: 'white' }}>No</label>
                        </div>
                      </div>
                      {btnClick && !isGstStatusSelected && 
                        <div style={{ color: '#dc3545', fontSize: '12px', marginTop: '0.25rem', textAlign: 'left' }}>Please select your GST registration status</div>
                      }
                    </div>

                    {/* PayPal ID Input */}
                    <div style={{ marginBottom: '1rem', textAlign: 'left' }}>
                      <label htmlFor="affPaypal" style={{ color: 'white', fontSize: '14px', marginBottom: '5px', textAlign: 'left', display: 'block' }}>PayPal Email</label>
                      <input 
                        type="text" 
                        style={{
                          height: '40px',
                          padding: '0.5rem 0.75rem',
                          color: '#fff',
                          backgroundColor: '#1e1e30',
                          border: '1px solid #2d2d3d',
                          borderRadius: '4px',
                          transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
                          textAlign: 'left',
                          width: '100%'
                        }}
                        className={`${btnClick && !isPaymentIdProvided ? 'error' : ""}`} 
                        id="affPaypal" 
                        value={paypalId} 
                        onChange={(e) => { setPaypalId(e.target.value); setBtnClick(false); setApiError(null); }} 
                        disabled={isLoading} 
                      />
                      {btnClick && !isPaymentIdProvided && 
                        <div style={{ color: '#dc3545', fontSize: '12px', marginTop: '0.25rem', textAlign: 'left' }}>Please provide your PayPal Email</div>
                      }
                    </div>

                    {/* Industry Input */}
                    <div style={{ marginBottom: '1rem', textAlign: 'left' }}>
                      <label htmlFor="affIndustry" style={{ color: 'white', fontSize: '14px', marginBottom: '5px', textAlign: 'left', display: 'block' }}>Your Primary Industry</label>
                      <input 
                        type="text" 
                        style={{
                          height: '40px',
                          padding: '0.5rem 0.75rem',
                          color: '#fff',
                          backgroundColor: '#1e1e30',
                          border: '1px solid #2d2d3d',
                          borderRadius: '4px',
                          transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
                          textAlign: 'left',
                          width: '100%'
                        }}
                        className={`${btnClick && !isIndustryValid ? 'error' : ""}`} 
                        id="affIndustry" 
                        value={industry} 
                        onChange={(e) => { setIndustry(e.target.value); setBtnClick(false); setApiError(null); }} 
                        disabled={isLoading} 
                      />
                      {btnClick && !isIndustryValid && 
                        <div style={{ color: '#dc3545', fontSize: '12px', marginTop: '0.25rem', textAlign: 'left' }}>Please enter your primary industry</div>
                      }
                    </div>

                    {/* Promotion Strategy Textarea */}
                    <div style={{ marginBottom: '1rem', textAlign: 'left' }}>
                      <label htmlFor="affPromotionStrategy" style={{ color: 'white', fontSize: '14px', marginBottom: '5px', textAlign: 'left', display: 'block' }}>
                        How will you promote MPLOI Accounts Receivable?
                      </label>
                      <textarea
                        style={{
                          minHeight: '120px',
                          resize: 'vertical',
                          backgroundColor: '#1e1e30',
                          color: '#fff',
                          border: '1px solid #2d2d3d',
                          padding: '0.5rem 0.75rem',
                          textAlign: 'left',
                          width: '100%',
                          borderRadius: '4px'
                        }}
                        className={`${btnClick && !isPromotionStrategyValid ? 'error' : ""}`}
                        id="affPromotionStrategy"
                        value={promotionStrategy}
                        onChange={(e) => { setPromotionStrategy(e.target.value); setBtnClick(false); setApiError(null); }}
                        disabled={isLoading}
                      ></textarea>
                      {btnClick && !isPromotionStrategyValid && 
                        <div style={{ color: '#dc3545', fontSize: '12px', marginTop: '0.25rem', textAlign: 'left' }}>Please describe your promotion strategy</div>
                      }
                    </div>

                    {/* Agreement Checkbox */}
                    <div style={{ marginBottom: '1rem', textAlign: 'left', display: 'flex', alignItems: 'flex-start' }}>
                      <input 
                        type="checkbox" 
                        id="affAgreeTerms" 
                        checked={agreedToTerms} 
                        onChange={(e) => { setAgreedToTerms(e.target.checked); setBtnClick(false); setApiError(null); }} 
                        disabled={isLoading} 
                        style={{
                          cursor: 'pointer',
                          accentColor: '#06EFC5',
                          marginRight: '8px',
                          marginTop: '4px'
                        }} 
                      />
                      <label htmlFor="affAgreeTerms" style={{ cursor: 'pointer', marginLeft: '8px', color: 'white', fontSize: '14px' }}>
                        I agree to the&nbsp;
                        <span 
                          style={{ cursor: 'pointer', textDecoration: 'underline', color: '#06EFC5', fontWeight: 'bold' }} 
                          onClick={() => !isLoading && props.history.push(affiliateAgreement)}
                        >
                          Affiliate Agreement
                        </span>
                      </label>
                    </div>

                    {/* API Error Display */}
                    {apiError && (
                      <div className="alert alert-danger mt-3" role="alert" style={{ textAlign: 'left' }}>
                        {apiError}
                      </div>
                    )}

                    {/* Green Signup Button */}
                    <button
                      type="submit"
                      style={{
                        backgroundColor: '#06EFC5',
                        color: '#1e1e30',
                        border: 'none',
                        padding: '10px 24px',
                        borderRadius: '4px',
                        cursor: isFormValid && !isLoading ? 'pointer' : 'not-allowed',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        transition: 'all 0.3s ease',
                        height: '45px',
                        width: '100%',
                        marginTop: '20px',
                        marginBottom: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        opacity: isFormValid && !isLoading ? 1 : 0.7
                      }}
                      disabled={!isFormValid || isLoading}
                    >
                      {isLoading ? (
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                      ) : null}
                      SIGNUP
                    </button>

                  </div>
                </div>
              </div>
            </div>

            {/* Right Column (Desktop Login Button) */}
            <div className="col-md-3 col-lg-4 col-xl-4 col-12 d-none d-md-block">
              <div className="right-wrap">
                <div className="btn-maccount">
                  <button 
                    type="button"
                    style={{
                      backgroundColor: 'rgba(6, 239, 197, 0.1)',
                      border: '1px solid #06EFC5',
                      color: '#06EFC5',
                      padding: '8px 16px',
                      borderRadius: '4px',
                      cursor: 'pointer',
                      fontSize: '14px',
                      transition: 'all 0.3s ease',
                      height: '35px',
                      whiteSpace: 'nowrap',
                      display: 'inline-flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                    onClick={() => !isLoading && props.history.push(loginPage)}
                    disabled={isLoading}
                  >
                    {STRING_CONSTANTS.signupPages.signup.account_exists}
                  </button>
                </div>
              </div>
            </div>

          </div>
        </form>
      </section>
    </Fragment>
  );
};

export default withRouter(ARAffiliateSignup);