import React from 'react';
import './Banner.css';
import { UtilityFunctions } from "../../utils/UtilityFunctions";

function Banner() {
  return (
    <div className="container-fluid banner-wrap position-relative">
      {/* Full-width background image div that sits behind everything */}
      <div className="banner-background-image">
        <img 
          src={`${process.env.PUBLIC_URL}/assets/images/trans_eye_png.png`} 
          alt="" 
          className="full-width-eye-image"
        />
      </div>
      
      <div className="row position-relative">
        <div className="col-lg-7 col-md-12 banner-content">
          <div className="title text-left">
            <span className="embrace-text">Embrace the AI Revolution</span> and Unleash Your Potential with MPLOI - The AI-Powered Platform That Puts You in Control.
          </div>
          <div className="description">
            In a fast-paced world where success favors the bold, MPLOI is your secret weapon to conquer the business landscape and skyrocket your career. This isn't just another platform; it's your personalized launchpad to unprecedented business growth and personal development.
          </div>
          <div className='mob-banner-img'>
            <img src={`${process.env.PUBLIC_URL}/assets/images/m_hp_img_png.png`} className='img-fluid' alt=''/>
          </div>
          <div className="banner-buttons d-flex flex-column flex-md-row gap-2 mt-4">
            <a href="/signup"><button className="btn-dark">Join MPLOI today</button></a> 
          </div>
          <div className='testimonial-card'>
            <div className='testimonial-quote'> <img src={`${process.env.PUBLIC_URL}/assets/images/testimonial-quote.png`} alt=''/></div>
            <div className='title'>
              <img src={`${process.env.PUBLIC_URL}/assets/images/testimonial-img.png`} alt='' />
              "I landed the job!"
            </div>
            <div className='description'>Interview Master AI was a game-changer for me. The detailed feedback helped me refine my responses and I went into my actual interview feeling prepared and confident.</div>
            <div className='client-name'>Marcus S</div>
          </div>
        </div>
        <div className="col-lg-5 col-md-12">
          {/* This column is empty but takes up space */}
        </div>
      </div>
    </div>
  );
}

export default Banner;