import React from 'react';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Helmet } from 'react-helmet';

const PDFViewer = () => {
    return (
        <div style={{height: '100%'}}>
            <iframe 
                src="../assets/documents/mploi-privacy.pdf" 
                width="100%" 
                height="100%"
                title="MPLOI Privacy Policy"
            />
        </div>
    );
};  

export default function Privacy() {
    // Scroll to top on component mount
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="termsPageWrap">
            <Helmet>
                <title>MPLOI: Privacy Policy</title>
                <meta name="description" content="MPLOI Privacy Policy - Read our terms of service" />
            </Helmet>
            
            <Header />
            
            <div className="main-content">
                <div className="tab-pane fade show active" id="panel4" role="tabpane" aria-labelledby="panel4-tab">
                    <div className="container-fluid">
                        <div className='title-row'>
                            <h1>Privacy Policy</h1>
                        </div>
                        <div className="blue-shadow-box" style={{
                            height: '800px',
                            margin: '20px 0 40px 0',
                            borderRadius: '8px',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                            overflow: 'hidden'
                        }}>
                            <PDFViewer />
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}