import React, { useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { UtilityFunctions } from '../../utils/UtilityFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from "redux";
import { actionCreators, State } from "../../store";
import { cookieService, userService } from '../../_services';
import UpgradePlanCheck from "../Dashboard/Home/upgradePlanCheck";
import { STRING_CONSTANTS } from '../../utils/constants/stringConstants';
import ValidationErrorToast from '../../components/validationErrorToast';
import SuccessToast from '../../components/successToast';
import { fetchSubscriptionChose } from "../../store/reducers/subscriptionPlans/apiThunk";
import SubscriptionModalAccounts from '../Dashboard/Home/SubscriptionModalAccounts';
import moment from 'moment';

const styles = {
    container: {
        backgroundColor: '#1a1a2e',
        minHeight: '100vh',
    },
    topBar: {
        background: 'rgba(255, 255, 255, 0.05)',
        padding: '16px 24px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '24px',
    },
    topBarTitle: {
        color: '#ffffff',
        margin: 0,
        fontSize: '24px',
        fontWeight: '500',
    },
    buttonGroup: {
        display: 'flex',
        gap: '12px',
    },
    button: {
        padding: '10px 20px',
        borderRadius: '6px',
        border: 'none',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        fontSize: '14px',
        fontWeight: '500',
    },
    primaryButton: {
        backgroundColor: '#007bff',
        color: '#ffffff',
    },
    secondaryButton: {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        color: '#ffffff',
    },
    blueBox: {
        background: 'rgba(255, 255, 255, 0.05)',
        borderRadius: '8px',
        padding: '20px',
        marginBottom: '20px',
    },
    whiteText: {
        color: '#ffffff',
    },
    mutedText: {
        color: 'rgba(255, 255, 255, 0.6)',
    },
    statsCard: {
        display: 'flex',
        alignItems: 'center',
    },
    statsContent: {
        marginLeft: '15px',
    },
    table: {
        width: '100%',
        color: '#fff',
        borderCollapse: 'separate',
        borderSpacing: '0 8px',
    },
    tableHeader: {
        color: 'rgba(255, 255, 255, 0.6)',
        fontWeight: '500',
        padding: '15px',
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
        textAlign: 'left',
    },
    tableCell: {
        padding: '15px',
        borderTop: '1px solid rgba(255, 255, 255, 0.1)',
        color: '#fff',
    },
    badge: {
        padding: '6px 12px',
        borderRadius: '4px',
        fontWeight: '500',
        display: 'inline-block',
    },
    campaignBox: {
        background: 'rgba(255, 255, 255, 0.05)',
        borderRadius: '12px',
        padding: '24px',
        marginBottom: '24px',
    },
    input: {
        width: '100%',
        padding: '12px',
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        border: '1px solid rgba(255, 255, 255, 0.2)',
        borderRadius: '6px',
        color: '#fff',
        marginBottom: '16px',
    },
    modal: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000,
    },
    modalContent: {
        background: '#1a1a2e',
        padding: '24px',
        borderRadius: '12px',
        width: '90%',
        maxWidth: '500px',
        border: '1px solid rgba(255, 255, 255, 0.1)',
    }
};

// Helper functions
const capitalizeFirst = (str) => {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
};

interface PhoneMarketingSystemProps {
    history: any;
}

interface CallLog {
    id: string;
    time: string;
    name: string;
    number: string;
    status: string;
    result: string;
    transcript?: string;
}

const PhoneMarketingSystem: React.FC<PhoneMarketingSystemProps> = (props) => {
    const dispatch = useDispatch();
    const state = useSelector((state: State) => state);
    
    // User and authentication state
    const [userData, setUserData] = useState(null);
    const [showGuestAccessPrompt, setShowGuestAccessPrompt] = useState(false);
    const [unreadMsgCount, setUnreadMsgCount] = useState(0);
    const [subsModalVisibility, setSubsModalVisibility] = useState(false);
    const user = cookieService?.getCookie(STRING_CONSTANTS?.cookieStrings?.user);
    const isUserLoggedIn = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.token);
    const { setUnreadNotificationCount } = bindActionCreators(actionCreators, dispatch);

    // Campaign state
    const [loading, setLoading] = useState(false);
    const [category, setCategory] = useState('automotive');
    const [callCount, setCallCount] = useState(100);
    const [callScript, setCallScript] = useState('');
    const [activeCalls, setActiveCalls] = useState(0);
    const [completedCalls, setCompletedCalls] = useState(0);
    const [callLogs, setCallLogs] = useState<CallLog[]>([]);

    // Modal state
    const [showTranscriptModal, setShowTranscriptModal] = useState(false);
    const [currentTranscript, setCurrentTranscript] = useState<string | null>(null);
    const [showTestCallModal, setShowTestCallModal] = useState(false);
    const [testCallPhone, setTestCallPhone] = useState('');
    const [selectedCall, setSelectedCall] = useState<CallLog | null>(null);

    // Stats state
    const [stats, setStats] = useState({
        callsToday: 0,
        totalCalls: 0,
        successRate: 0,
        averageDuration: 0
    });

    // Effect for initial data loading
    useEffect(() => {
        if (isUserLoggedIn) {
            userService.getUserData()
                .then(res => {
                    cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, res);
                    setUnreadNotificationCount(res?.user?.unreadNotificationCount);
                    setUserData(res);
                    setTestCallPhone(res?.user?.mobileNumber);
                })
                .catch(error => {
                    console.error('Error fetching user data:', error);
                    ValidationErrorToast("Error", "Failed to load user data");
                });
        }
    }, [isUserLoggedIn, setUnreadNotificationCount]);

    // Effect for subscription check
    useEffect(() => {
        const checkSubscription = async () => {
            try {
                const res = await dispatch(fetchSubscriptionChose());
                setSubsModalVisibility(Object.keys(res.payload).length === 0);
            } catch (error) {
                console.error("Subscription check error:", error);
                ValidationErrorToast("Error", "Failed to verify subscription status");
            }
        };

        checkSubscription();
    }, [dispatch]);

    // Campaign handlers
    const handleStartCampaign = async () => {
        if (!callScript) {
            ValidationErrorToast("Error", "Please enter a call script first");
            return;
        }

        setLoading(true);
        try {
            const response = await userService.aiRequest({
                method: 'start-campaign',
                data: {
                    category,
                    callCount,
                    script: callScript
                }
            });

            if (response.data?.success) {
                SuccessToast("Success", "Campaign started successfully");
                simulateCallLogs(callCount);
            } else {
                throw new Error(response.data?.error || 'Unknown error');
            }
        } catch (error) {
            ValidationErrorToast("Error", `Failed to start campaign: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    const simulateCallLogs = (count: number) => {
        const names = ['John Smith', 'Emma Wilson', 'Michael Brown', 'Sarah Davis', 'James Miller'];
        const statuses = ['Success', 'Failed', 'No Answer'];
        const results = ['Interested', 'Not Interested', 'Booked Meeting'];
        
        let processed = 0;
        const interval = setInterval(() => {
            if (processed >= count) {
                clearInterval(interval);
                return;
            }

            const newLog = {
                id: `call-${Date.now()}-${processed}`,
                time: new Date().toLocaleTimeString(),
                name: names[Math.floor(Math.random() * names.length)],
                number: `(${Math.floor(Math.random() * 900) + 100}) ${Math.floor(Math.random() * 900) + 100}-${Math.floor(Math.random() * 9000) + 1000}`,
                status: statuses[Math.floor(Math.random() * statuses.length)],
                result: results[Math.floor(Math.random() * results.length)],
                transcript: "Sample transcript content..."
            };

            setCallLogs(prev => [newLog, ...prev]);
            setCompletedCalls(prev => prev + 1);
            processed++;
        }, 2000);
    };

    // Call handlers
    const handleTestCall = async () => {
        if (!testCallPhone) {
            ValidationErrorToast("Error", "Please enter a phone number");
            return;
        }

        try {
            const response = await userService.aiRequest({
                method: 'initiate-test-call',
                data: {
                    phone: testCallPhone,
                    callId: selectedCall?.id,
                    script: callScript
                }
            });

            if (response.data?.success) {
                SuccessToast("Success", "Test call initiated successfully");
                setShowTestCallModal(false);
            } else {
                throw new Error(response.data?.error || 'Failed to initiate test call');
            }
        } catch (error) {
            ValidationErrorToast("Error", error.message);
        }
    };

    const handleCallCountChange = useCallback((value: string) => {
        const numValue = parseInt(value, 10);
        setCallCount(numValue);
        const slider = document.getElementById('callCount');
        if (slider) {
            slider.style.setProperty('--value', `${numValue/10}%`);
        }
    }, []);


        // Component for the Campaign Setup Section
        const CampaignSetup = () => (
            <div style={styles.campaignBox}>
                <h2 style={styles.whiteText} className="mb-4">Campaign Setup</h2>
                <div className="row">
                    <div className="col-md-6 mb-4">
                        <label className="text-blue-300 mb-2">Category</label>
                        <select
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                            style={styles.input}
                        >
                            <option value="automotive">Automotive</option>
                            <option value="real-estate">Real Estate</option>
                            <option value="insurance">Insurance</option>
                            <option value="healthcare">Healthcare</option>
                            <option value="technology">Technology</option>
                        </select>
                    </div>
                    <div className="col-md-6 mb-4">
                        <label className="text-blue-300 mb-2">Number of Calls</label>
                        <div className="d-flex align-items-center">
                            <input
                                type="range"
                                id="callCount"
                                min="1"
                                max="1000"
                                value={callCount}
                                onChange={(e) => handleCallCountChange(e.target.value)}
                                className="w-100 me-3"
                            />
                            <span style={styles.whiteText}>{callCount}</span>
                        </div>
                    </div>
                    <div className="col-12 mb-4">
                        <label className="text-blue-300 mb-2">Call Script</label>
                        <textarea
                            value={callScript}
                            onChange={(e) => setCallScript(e.target.value)}
                            rows={6}
                            style={styles.input}
                            placeholder="Enter your call script here..."
                        />
                    </div>
                    <div className="col-12">
                        <button
                            onClick={handleStartCampaign}
                            disabled={loading}
                            className="btn btn-primary w-100"
                            style={styles.primaryButton}
                        >
                            {loading ? (
                                <>
                                    <i className="fas fa-spinner fa-spin me-2" />
                                    Starting Campaign...
                                </>
                            ) : (
                                <>
                                    <i className="fas fa-phone-volume me-2" />
                                    Start Calling Campaign
                                </>
                            )}
                        </button>
                    </div>
                </div>
            </div>
        );
    
        // Component for Call Logs
        const CallLogsSection = () => (
            <div style={styles.blueBox}>
                <div className="d-flex justify-content-between align-items-center mb-4">
                    <h3 style={styles.whiteText}>Call Log</h3>
                    <div className="d-flex gap-4">
                        <span style={styles.mutedText}>
                            <i className="fas fa-phone-alt me-2" />
                            Active: {activeCalls}
                        </span>
                        <span style={styles.mutedText}>
                            <i className="fas fa-check me-2" />
                            Completed: {completedCalls}
                        </span>
                    </div>
                </div>
                <div className="table-responsive">
                    <table style={styles.table}>
                        <thead>
                            <tr>
                                <th style={styles.tableHeader}>Time</th>
                                <th style={styles.tableHeader}>Name</th>
                                <th style={styles.tableHeader}>Number</th>
                                <th style={styles.tableHeader}>Status</th>
                                <th style={styles.tableHeader}>Result</th>
                                <th style={styles.tableHeader}>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {callLogs.map((log) => (
                                <tr key={log.id}>
                                    <td style={styles.tableCell}>{log.time}</td>
                                    <td style={styles.tableCell}>{log.name}</td>
                                    <td style={styles.tableCell}>{log.number}</td>
                                    <td style={styles.tableCell}>
                                        <span className={`badge ${
                                            log.status === 'Success' ? 'bg-success' :
                                            log.status === 'Failed' ? 'bg-danger' :
                                            'bg-warning'
                                        }`}>
                                            {log.status}
                                        </span>
                                    </td>
                                    <td style={styles.tableCell}>
                                        <span className={`badge ${
                                            log.result === 'Interested' ? 'bg-primary' :
                                            log.result === 'Booked Meeting' ? 'bg-success' :
                                            'bg-secondary'
                                        }`}>
                                            {log.result}
                                        </span>
                                    </td>
                                    <td style={styles.tableCell}>
                                        <div className="d-flex gap-2">
                                            <button
                                                onClick={() => {
                                                    setSelectedCall(log);
                                                    setShowTestCallModal(true);
                                                }}
                                                className="btn btn-sm"
                                                style={styles.secondaryButton}
                                            >
                                                Test Call
                                            </button>
                                            <button
                                                onClick={() => {
                                                    setCurrentTranscript(log.transcript);
                                                    setShowTranscriptModal(true);
                                                }}
                                                className="btn btn-sm"
                                                style={styles.secondaryButton}
                                            >
                                                View Transcript
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    
        // Stats Display Component
        const StatsDisplay = () => (
            <div className="row mb-4">
                <div className="col-md-3">
                    <div style={styles.blueBox}>
                        <div style={styles.statsCard}>
                            <i className="fas fa-phone-volume fa-2x text-primary" />
                            <div style={styles.statsContent}>
                                <h4 style={styles.mutedText}>Calls Today</h4>
                                <h2 style={styles.whiteText}>{stats.callsToday}</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div style={styles.blueBox}>
                        <div style={styles.statsCard}>
                            <i className="fas fa-chart-line fa-2x text-success" />
                            <div style={styles.statsContent}>
                                <h4 style={styles.mutedText}>Total Calls</h4>
                                <h2 style={styles.whiteText}>{stats.totalCalls}</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div style={styles.blueBox}>
                        <div style={styles.statsCard}>
                            <i className="fas fa-check-circle fa-2x text-info" />
                            <div style={styles.statsContent}>
                                <h4 style={styles.mutedText}>Success Rate</h4>
                                <h2 style={styles.whiteText}>{stats.successRate}%</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div style={styles.blueBox}>
                        <div style={styles.statsCard}>
                            <i className="fas fa-clock fa-2x text-warning" />
                            <div style={styles.statsContent}>
                                <h4 style={styles.mutedText}>Avg Duration</h4>
                                <h2 style={styles.whiteText}>
                                    {formatDuration(stats.averageDuration)}
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    
        // Modal Components
        const TestCallModal = () => (
            <div className="modal" style={{
                display: showTestCallModal ? 'block' : 'none',
                ...styles.modal
            }}>
                <div style={styles.modalContent}>
                    <h3 style={styles.whiteText} className="mb-4">Test Call</h3>
                    <p style={styles.mutedText}>
                        This will initiate a test call for {selectedCall?.name || ''}, 
                        but route it to your specified phone number.
                    </p>
                    <input
                        type="tel"
                        value={testCallPhone}
                        onChange={(e) => setTestCallPhone(e.target.value)}
                        placeholder="Enter your phone number"
                        style={styles.input}
                    />
                    <div className="d-flex justify-content-end gap-2">
                        <button
                            onClick={() => setShowTestCallModal(false)}
                            className="btn"
                            style={styles.secondaryButton}
                        >
                            Cancel
                        </button>
                        <button
                            onClick={handleTestCall}
                            className="btn"
                            style={styles.primaryButton}
                        >
                            Start Test Call
                        </button>
                    </div>
                </div>
            </div>
        );
    
        const TranscriptModal = () => (
            <div className="modal" style={{
                display: showTranscriptModal ? 'block' : 'none',
                ...styles.modal
            }}>
                <div style={styles.modalContent}>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <h3 style={styles.whiteText}>Call Transcript</h3>
                        <button
                            onClick={() => setShowTranscriptModal(false)}
                            className="btn-close btn-close-white"
                        />
                    </div>
                    <div style={{
                        ...styles.blueBox,
                        maxHeight: '400px',
                        overflowY: 'auto'
                    }}>
                        {currentTranscript}
                    </div>
                </div>
            </div>
        );

        return (
            <section className="main-dashboard-wrap" style={styles.container}>
                <Helmet>
                    <title>MPLOI: Phone Marketing System</title>
                </Helmet>
                <div id="wrapper" style={{ height: "100vh" }}>
                    {/* Sidebar */}
                    <ul className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ${state.common.showSidebarOnMobileView ? "active" : ""}`} id="accordionSidebar">
                        <div>
                            <Link 
                                style={{ pointerEvents: "none" }} 
                                className="sidebar-brand d-flex align-items-center justify-content-center" 
                                to="#"
                            >
                                <img src={UtilityFunctions.getImageURL() + "db-logo.svg"} alt="Logo" />
                            </Link>
                            {!user?.user?.isAccountsSignup && (
                                <li className="nav-item">
                                    <Link className="nav-link" to="/dashboard/home">
                                        <img src={UtilityFunctions.getImageURL() + "home-icon-blank-white.svg"} alt="" />
                                        <img src={UtilityFunctions.getImageURL() + "home-icon-fill-green.svg"} alt="" />
                                    </Link>
                                </li>
                            )}
                            {user?.user?.isAccountsSignup && (
                                <li className="nav-item">
                                    <Link className="nav-link" to="/ar">
                                        <img src={UtilityFunctions.getImageURL() + "home-icon-blank-white.svg"} alt="" />
                                        <img src={UtilityFunctions.getImageURL() + "home-icon-fill-green.svg"} alt="" />
                                    </Link>
                                </li>
                            )}
    
                            {!user?.user?.isAccountsSignup && (
                                <li className="nav-item notification-nav">
                                    <Link className="nav-link" to="/dashboard/chat/">
                                        <div className="svg-box">
                                            <img src={UtilityFunctions.getImageURL() + "chat-icon.svg"} alt="" />
                                            <img src={UtilityFunctions.getImageURL() + "chat-icon-fill-green.svg"} alt="" />
                                            {unreadMsgCount ? (
                                                <span>{unreadMsgCount > 9 ? "9+" : unreadMsgCount}</span>
                                            ) : ""}
                                        </div>
                                    </Link>
                                </li>
                            )}
    
                            {user?.user?.type === STRING_CONSTANTS.userType.employer && (
                                <li className="nav-item">
                                    <Link className="nav-link" to="/dashboard/modules">
                                        <img 
                                            src={UtilityFunctions.getImageURL() + "employeesLandingPage/exploreFifth.svg"}
                                            style={{ filter: 'brightness(0) invert(1)' }} 
                                            alt="" 
                                        />
                                        <img 
                                            src={UtilityFunctions.getImageURL() + "employeesLandingPage/exploreFifth.svg"}
                                            style={{ filter: 'brightness(0) invert(1)' }} 
                                            alt="" 
                                        />
                                    </Link>
                                </li>
                            )}
                        </div>
                        <div>
                            <li className="nav-item">
                                <UpgradePlanCheck
                                    isUserLoggedIn={isUserLoggedIn}
                                    setShowGuestAccessPrompt={setShowGuestAccessPrompt}
                                    userData={userData}
                                />
                            </li>
                        </div>
                        <div>
                            <li className="nav-item notification-nav">
                                <span 
                                    className="nav-link"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        isUserLoggedIn
                                            ? props.history.push(STRING_CONSTANTS.navigationString.dashboard.notifications)
                                            : setShowGuestAccessPrompt(true)
                                    }}
                                >
                                    <div className="svg-box">
                                        <img src={UtilityFunctions.getImageURL() + "bell-icon-blank-white.svg"} alt="" />
                                        <img src={UtilityFunctions.getImageURL() + "bell-icon-fill-green.svg"} alt="" />
                                        {state?.unreadNotificationCount?.unreadNotificationCount ? (
                                            <span>
                                                {state?.unreadNotificationCount?.unreadNotificationCount > 9
                                                    ? "9+"
                                                    : state?.unreadNotificationCount?.unreadNotificationCount}
                                            </span>
                                        ) : ""}
                                    </div>
                                </span>
                            </li>
                            <li className="nav-item">
                                <span 
                                    className="nav-link"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        isUserLoggedIn
                                            ? props.history.push(STRING_CONSTANTS.navigationString.dashboard.settings)
                                            : setShowGuestAccessPrompt(true)
                                    }}
                                >
                                    <img src={UtilityFunctions.getImageURL() + "settings-icon-blank-white.svg"} alt="" />
                                    <img src={UtilityFunctions.getImageURL() + "settings-icon-fill-green.svg"} alt="" />
                                </span>
                            </li>
                            <li className='profile-pic d-grid justify-content-center gap-2 text-white'>
                                <img 
                                    src={userData?.user?.type === STRING_CONSTANTS.userType.employer
                                        ? (userData?.logo || UtilityFunctions.getImageURL() + "profile-pic.png")
                                        : (userData?.profilePhoto || UtilityFunctions.getImageURL() + "default-employee-profile-pic-round.svg")
                                    }
                                    alt="Profile"
                                />
                                {!isUserLoggedIn && (
                                    <span 
                                        style={{ cursor: "pointer" }}
                                        onClick={() => props.history.push({
                                            pathname: STRING_CONSTANTS.navigationString.signup,
                                            state: { isGuest: true }
                                        })}
                                    >
                                        Signup
                                    </span>
                                )}
                            </li>
                        </div>
                    </ul>
    
                    {/* Content Wrapper */}
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content">
                            {/* Top Bar */}
                            <div style={styles.topBar}>
                                <h1 style={styles.topBarTitle}>Phone Marketing System</h1>
                                <div style={styles.buttonGroup}>
                                    <button 
                                        style={{...styles.button, ...styles.secondaryButton}}
                                        onClick={() => props.history.push('/marketing/settings')}
                                    >
                                        <i className="fas fa-cog" />
                                        Settings
                                    </button>
                                </div>
                            </div>
    
                            {/* Main Content */}
                            <div className="container-fluid">
                                <StatsDisplay />
                                <CampaignSetup />
                                <CallLogsSection />
                            </div>
                        </div>
                    </div>
                </div>
    
                {/* Modals */}
                {/* <TestCallModal />
                <TranscriptModal /> */}
                {/* <SubscriptionModalAccounts
                    subsModal={subsModalVisibility}
                    setSubsModal={setSubsModalVisibility}
                    history={props.history}
                /> */}
            </section>
        );
    };
    
    export default PhoneMarketingSystem;