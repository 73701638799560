import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom"
import UpdatePlan from "../../../components/updatePlan";
import { api } from "../../../middleware/api";
import { STRING_CONSTANTS } from "../../../utils/constants/stringConstants";
import { UtilityFunctions } from "../../../utils/UtilityFunctions";
import { cookieService } from "../../../_services";
import JobCreation from '../Job Creation';
import VerifyUser from "../../../components/verifyUser";
import { Data, User } from "../../../_services/collections";

const UpgradePlanCheck = (props: any) => {
    const [totalJobCount, setTotalJobCount] = useState(0);
    const [upgradePlan, setUpgradePlan] = useState(false);
    var [show, setShow] = useState(false);
    const [verifyPlan, setVerifyPlan] = useState(false);
    var [showVerifyPlan, setShowVerifyPlan] = useState(false);
    const isUserLoggedIn = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.token)
    const userData = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user)
    // fetch all the jobs
    useEffect(() => {
        // api.post('jobs/?limit=7', null)
        //     .then(res => {
        //         setTotalJobCount(res?.data?.count)
        //console.log("total job count",totalJobCount)
        //console.log("plan selected",cookieService.getCookie(STRING_CONSTANTS.cookieStrings.planSelected))
        //console.log("total job count res",res?.data?.count)
        // if(cookieService.getCookie(STRING_CONSTANTS.cookieStrings.planSelected) === "Basic"){
        //   if(res?.data?.count>0){
        //     setUpgradePlan(true);
        //   }
        // }
        // else if(cookieService.getCookie(STRING_CONSTANTS.cookieStrings.planSelected) === "Silver"){
        //   if(res?.data?.count>4){
        //     setUpgradePlan(true);
        //   }
        // }
        // else if(cookieService.getCookie(STRING_CONSTANTS.cookieStrings.planSelected) === "Gold"){
        //   if(res?.data?.count>9){
        //     setUpgradePlan(true);
        //   }
        // }
        // else{
        //     setUpgradePlan(false)
        // }
        // if (!(cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user) as Data).user?.isVerified) {
        //     setVerifyPlan(true)
        // }
        // else if (parseInt(cookieService.getCookie(STRING_CONSTANTS.cookieStrings.planSelected))) {
        //     setUpgradePlan(false)   //reverted flag as BE changes not working
        // }
        // else {
        //     setUpgradePlan(true)
        // }
        // setUpgradePlan(false)
        // }).catch(e => console.log(e))
    }, [])

    const handleClose = () => {
        setShow(false);
    }
    const handleClose1 = () => {
        setShowVerifyPlan(false);
    }
    const checkJobCreation = () => {
        if (!isUserLoggedIn) {
            props?.setShowGuestAccessPrompt(true)
        }
        else if (userData?.user?.type === STRING_CONSTANTS.userType.employer) {
            if (verifyPlan) {
                setShowVerifyPlan(true);
            }
            // else if (upgradePlan) {
            //     setShow(true);
            // }
            // else {
            //     if (userData?.user?.isAccountsSignup) {
            //     } else {
            //         props.history.push(STRING_CONSTANTS.navigationString.dashboard.job_creation);
            //     }
            // }
        }
        // else {
        //     console.log("THIS PATH HAPPENING!")
        //     props.history.push({
        //         pathname: STRING_CONSTANTS.navigationString.employee_profile_completion,
        //         state: { step: 3, updateProfile: true }
        //     });
        // }
    }

    return (
        <>
            <li className="nav-item">
                {upgradePlan}
                <div className="nav-link">
                    {userData?.user?.type === STRING_CONSTANTS.userType.employer ?
                        <>
                            <img src={UtilityFunctions.getImageURL() + "menu-plus-icon.svg"} alt="" onClick={checkJobCreation} />
                            <img src={UtilityFunctions.getImageURL() + "menu-plus-icon-green.svg"} alt="" onClick={checkJobCreation} />
                            {/* <svg width="79" height="79" viewBox="0 0 79 79" fill="none" xmlns="http://www.w3.org/2000/svg"
                                //onClick={()=>props.hisstory}
                                onClick={checkJobCreation}
                            >
                                <g filter="url(#filter0_f_3401_11532)">
                                    <path d="M40 22C49.9411 22 58 30.0589 58 40C58 49.9411 49.9411 58 40 58C30.0589 58 22 49.9411 22 40C22 30.0589 30.0589 22 40 22ZM40 24.25C31.3015 24.25 24.25 31.3015 24.25 40C24.25 48.6985 31.3015 55.75 40 55.75C48.6985 55.75 55.75 48.6985 55.75 40C55.75 31.3015 48.6985 24.25 40 24.25ZM40 31C40.6213 31 41.125 31.5037 41.125 32.125V38.875H47.875C48.4963 38.875 49 39.3787 49 40C49 40.6213 48.4963 41.125 47.875 41.125H41.125V47.875C41.125 48.4963 40.6213 49 40 49C39.3787 49 38.875 48.4963 38.875 47.875V41.125H32.125C31.5037 41.125 31 40.6213 31 40C31 39.3787 31.5037 38.875 32.125 38.875H38.875V32.125C38.875 31.5037 39.3787 31 40 31Z" fill="white" />
                                </g>
                                <g filter="url(#filter1_f_3401_11532)">
                                    <circle cx="39.5" cy="39.5" r="32.25" stroke="#06EFC5" strokeWidth="1.5" />
                                </g>
                                <circle cx="40" cy="40" r="32.75" stroke="#06EFC5" strokeWidth="1.5" />
                                <path d="M40 22C49.9411 22 58 30.0589 58 40C58 49.9411 49.9411 58 40 58C30.0589 58 22 49.9411 22 40C22 30.0589 30.0589 22 40 22ZM40 24.25C31.3015 24.25 24.25 31.3015 24.25 40C24.25 48.6985 31.3015 55.75 40 55.75C48.6985 55.75 55.75 48.6985 55.75 40C55.75 31.3015 48.6985 24.25 40 24.25ZM40 31C40.6213 31 41.125 31.5037 41.125 32.125V38.875H47.875C48.4963 38.875 49 39.3787 49 40C49 40.6213 48.4963 41.125 47.875 41.125H41.125V47.875C41.125 48.4963 40.6213 49 40 49C39.3787 49 38.875 48.4963 38.875 47.875V41.125H32.125C31.5037 41.125 31 40.6213 31 40C31 39.3787 31.5037 38.875 32.125 38.875H38.875V32.125C38.875 31.5037 39.3787 31 40 31Z" fill="white" />
                                <defs>
                                    <filter id="filter0_f_3401_11532" x="13" y="13" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                        <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_3401_11532" />
                                    </filter>
                                    <filter id="filter1_f_3401_11532" x="0.5" y="0.5" width="78" height="78" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                        <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_3401_11532" />
                                    </filter>
                                </defs>
                            </svg> */}
                        </>
                        :
                        <>
                            <img src={UtilityFunctions.getImageURL() + "menu-pencil-icon.svg"} alt="" onClick={checkJobCreation} />
                            <img src={UtilityFunctions.getImageURL() + "menu-pencil-icon-green.svg"} alt="" onClick={checkJobCreation} />
                            {/* <svg width="79" height="79" viewBox="0 0 79 79" fill="none" xmlns="http://www.w3.org/2000/svg"
                                onClick={checkJobCreation}
                            >
                                <g filter="url(#filter0_f_12126_25273)">
                                    <circle cx="39.5" cy="39.5" r="32.25" stroke="#06EFC5" stroke-width="1.5" />
                                </g>
                                <circle cx="40" cy="40" r="32.75" stroke="#06EFC5" stroke-width="1.5" />
                                <path d="M40 22C49.9411 22 58 30.0589 58 40C58 49.9411 49.9411 58 40 58C30.0589 58 22 49.9411 22 40C22 30.0589 30.0589 22 40 22ZM40 24.25C31.3015 24.25 24.25 31.3015 24.25 40C24.25 48.6985 31.3015 55.75 40 55.75C48.6985 55.75 55.75 48.6985 55.75 40C55.75 31.3015 48.6985 24.25 40 24.25Z" fill="white" />
                                <path d="M47.0699 32.929C48.0462 33.9053 48.0462 35.4882 47.0699 36.4645L37.8368 45.6976C37.4363 46.0981 36.9345 46.3822 36.385 46.5196L33.5213 47.2355C33.0636 47.35 32.6489 46.9354 32.7634 46.4776L33.4793 43.6139C33.6167 43.0644 33.9008 42.5626 34.3013 42.1621L43.5344 32.929C44.5107 31.9527 46.0936 31.9527 47.0699 32.929ZM42.6504 35.5805L35.1852 43.046C34.9449 43.2863 34.7744 43.5874 34.692 43.917L34.2287 45.7702L36.0819 45.3069C36.4116 45.2245 36.7126 45.054 36.9529 44.8137L44.4179 37.348L42.6504 35.5805ZM44.4183 33.8129L43.5341 34.6968L45.3016 36.4643L46.186 35.5807C46.6742 35.0925 46.6742 34.301 46.186 33.8129C45.6979 33.3247 44.9064 33.3247 44.4183 33.8129Z" fill="white" />
                                <defs>
                                    <filter id="filter0_f_12126_25273" x="0.5" y="0.5" width="78" height="78" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                        <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_12126_25273" />
                                    </filter>
                                </defs>
                            </svg> */}
                        </>

                    }

                    {/* <img src={UtilityFunctions.getImageURL()+ 'add-icon.svg'} alt="" /> */}
                    {/* onClick={!upgradePlan ? props.history.push("/dashboard/job-creation/") : <UpdatePlan show={upgradePlan}/>} /> */}
                </div>
            </li>
            {show ? <UpdatePlan show={upgradePlan} handleClose={handleClose} /> : null}
            {showVerifyPlan ? <VerifyUser show={verifyPlan} handleClose={handleClose1} /> : null}
        </>
    )
}
export default withRouter(UpgradePlanCheck);
