import React, { useEffect, useState } from 'react';
import { UtilityFunctions } from "../../utils/UtilityFunctions";
import { cookieService, userService } from '../../_services';
import ToastHeader from "../../components/toastHeader";
import { withRouter } from 'react-router-dom';
import { STRING_CONSTANTS } from '../../utils/constants/stringConstants';
import Logout from '../../components/logout';

const PDFViewer = () => {
    return (
        <div style={{height: '100%'}}>
            <iframe 
                src="../assets/documents/mploi-terms.pdf" 
                width="100%" 
                height="100%"
                title="Terms and Conditions"
            />
        </div>
    );
};

const Terms = (props) => {
    const [userData, setUserData] = useState(null);
    const [show, setShow] = useState(false);

    useEffect(() => {
        setUserData(cookieService.getCookie(STRING_CONSTANTS.cookieStrings.user))
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            user: {
                isTermsConditionAccepted: true
            }
        }
        userService.userProfile(data)
            .then(() => userService.getUserData())
            .then(res => {
                cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, res)
            })
            .then(() => {
                window.location.reload()
            })
            .catch(function (error) {
                ToastHeader(error);
            });
    }

    return (
        <section className="main-signup-wrap blue-bg remove-bg-top-round">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-2 col-12">
                        <div className="left-wrap">
                            <div className="logo-wrap">
                                {!userData?.user?.id ? 
                                    <img alt="" src={UtilityFunctions.getImageURL() + "logo_White.svg"} 
                                         onClick={() => props.history.push('/')} /> :
                                    <img alt="" style={{ cursor: "default" }} 
                                         src={UtilityFunctions.getImageURL() + "logo_White.svg"} />}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-8 col-12 text-center">
                        <div className='my-4 my-sm-0 text-white d-flex justify-content-between align-items-center legalTitle'>
                            {!userData?.user?.id ?
                                <span style={{ cursor: "pointer" }}>
                                    <i className="fa fa-angle-left" aria-hidden="true" 
                                       onClick={() => props.history.push(STRING_CONSTANTS.navigationString.signup, 
                                       { step: 1, isGuest: props?.location?.state?.isGuest, 
                                         userType: props?.history?.location?.state?.userType, 
                                         account_exists: props?.location?.state?.account_exists })}>
                                    </i>
                                </span>
                                :
                                (userData?.user?.id && userData?.user?.isTermsConditionAccepted) ?
                                    <span style={{ cursor: "pointer" }}>
                                        <i className="fa fa-angle-left" aria-hidden="true" 
                                           onClick={() => props.history.goBack() ? props.history.goBack() : 
                                           props.history.push(STRING_CONSTANTS.navigationString.dashboard.settings)}>
                                        </i>
                                    </span>
                                    :
                                    <span style={{ cursor: "pointer" }}>
                                        <i className="text-white d-flex justify-content-between"
                                           aria-hidden="true"
                                           style={{ position: "fixed" }}
                                           onClick={() => setShow(true)}>
                                            <img src={UtilityFunctions.getImageURL() + "cross-icon-white.svg"} 
                                                 width={'22px'} height={'22px'} alt="" />
                                        </i>
                                    </span>
                            }
                            <p className='m-0 w-100'>Terms & Conditions</p>
                            <span></span>
                        </div>
                        <div className='terms-shadow2'>
                            <div className='legal-content' style={{height: '600px'}}>
                                <PDFViewer />
                            </div>
                        </div>
                        {userData?.user?.id && !userData?.user?.isTermsConditionAccepted && 
                            <button className='btn-cta terms-and-privacy' 
                                    onClick={(e) => { handleSubmit(e) }}>Accept</button>}
                    </div>
                    <div className="col-sm-2 col-12"> </div>
                </div>
            </div>
            <Logout
                show={show}
                handleClose={() => setShow(false)}
            />
        </section>
    )
}

export default withRouter(Terms);
